import React from 'react'
import { Container } from 'react-grid-system'

import LoadSvg from '../LoadSvg'
import Loader from '../../images/loader-dark.svg'
import styles from './index.module.css'

const Spinner = () => {
	return (
		<Container className={styles.container}>
			<LoadSvg className={styles.spinner} svg={Loader} />
		</Container>
	)
}

export default Spinner
