import { AppState } from '../../store/constants'
import { AuthActions } from './constants'

const initialState = {
	appState: AppState.READY,

	error: '',
}

export default (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case AuthActions.setError: {
			return {
				...state,
				error: payload,
			}
		}
		case AuthActions.clearError: {
			return {
				...state,
				error: '',
			}
		}
		case AuthActions.setAppState: {
			return {
				...state,
				appState: payload,
			}
		}
		default: {
			return state
		}
	}
}
