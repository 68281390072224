import { createSelector } from 'reselect'
import { transformUsers, transformUser, transformStories } from './transform'

export const selectQuery = createSelector(
	(state) => state.users,
	(users) => users.query,
)

export const selectAppState = createSelector(
	(state) => state.users,
	(users) => users.appState,
)

export const selectUsers = createSelector(
	(state) => state.users,
	(users) => transformUsers(users.list),
)

export const selectUser = createSelector(
	(state) => state.users,
	(users) => transformUser(users.user),
)

export const selectStories = createSelector(
	(state) => state.users,
	(users) => transformStories(users.stories),
)

export const selectPicture = createSelector(
	(state) => state.main,
	(main) => main.user.picture || '',
)
