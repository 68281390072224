import React, { useEffect } from 'react'
import { Row, Col, Hidden } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'

import { BackgroundImage, Font, Button, Input } from '../../../components'
import { selectError, selectAppState } from '../selector'
import { AppState } from '../../../store/constants'
import { requestForgotPassword, clearError } from '../actions'
import AlertliHeader from '../helper/Logo'

import styles from '../index.module.css'

function ForgotPassword() {
	const dispatch = useDispatch()

	const appError = useSelector(selectError)
	const appState = useSelector(selectAppState)

	const { register, handleSubmit, errors } = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
	})

	useEffect(() => {
		return () => {
			dispatch(clearError())
		}
	}, [dispatch])

	const onSubmit = (data) => {
		dispatch(requestForgotPassword(data.email))
	}

	return (
		<Row>
			<Col md={6} lg={7}>
				<Hidden xs sm>
					<BackgroundImage />
				</Hidden>
			</Col>
			<Col md={6} lg={5}>
				<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
					<AlertliHeader />
					<Font fontText='Forgot Password' fontAlias='H1' />
					<br />
					<Font
						fontAlias='P1'
						fontText='Enter email address that you use to login on the portal and we will send you a password reset link on your email address.'
					/>
					<div className={styles.spacingTopBtm} />
					<Input
						name='email'
						type='email'
						htmlFor='email'
						label='Email'
						inputRef={register({
							required: 'Email field  is required',
						})}
						errorText={
							(errors.email && errors.email.message) || appError
						}
					/>
					<Button
						buttonText='Continue'
						buttonAlias='Primary'
						loading={appState === AppState.LOADING}
						disabled={appState === AppState.LOADING}
					/>
				</form>
			</Col>
		</Row>
	)
}

export default ForgotPassword
