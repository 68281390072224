import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import Login from './domains/auth/Login'
import AuthRouter from './domains/auth/router'
import { routerRef } from './utils'

function UnauthenticatedApp() {
	return (
		<BrowserRouter
			ref={(e) => {
				routerRef.current = e
			}}
		>
			<Switch>
				<Route path='/auth/:path'>
					<AuthRouter />
				</Route>
				<Route exact path='/auth'>
					<Login />
				</Route>
				<Route
					exact
					path='*'
					render={() => {
						return <Redirect to='/auth' />
					}}
				/>
				{/* <Route path='*'> */}
				{/* <NotFound /> */}
				{/* </Route> */}
			</Switch>
		</BrowserRouter>
	)
}

export default UnauthenticatedApp
