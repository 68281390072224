import React from 'react'
import PropTypes from 'prop-types'

import styles from './DetailSection.module.css'

const DetailSection = ({ heading, paragraph }) => {
	return (
		<div>
			<h1 className={styles.storyHeading}>{heading}</h1>
			<p className={styles.storyDetail}>{paragraph}</p>
		</div>
	)
}

DetailSection.propTypes = {
	heading: PropTypes.string.isRequired,
	paragraph: PropTypes.string.isRequired,
}

export default DetailSection
