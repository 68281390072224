import React, { useEffect } from 'react'
import { Row, Col, Hidden } from 'react-grid-system'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'

import { BackgroundImage, Font, Input } from '../../../components'
import { requestLogin, clearError } from '../actions'
import { selectError, selectAppState } from '../selector'
import { AppState } from '../../../store/constants'
// import AlertliHeader from '../helper/Logo'
import LogoImage from '../../../images/logoWhite.png'
import LogoButton from '../../../images/loginbtn.png'
import LoadSvg from '../../../components/LoadSvg'
import Loader from '../../../images/loader-light.svg'

import styles from '../index.module.css'

function LoginPage() {
	const dispatch = useDispatch()

	const appError = useSelector(selectError)
	const appState = useSelector(selectAppState)

	const { register, handleSubmit, errors } = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
	})

	useEffect(() => {
		return () => {
			dispatch(clearError())
		}
	}, [dispatch])

	const onSubmit = ({ email, password }) => {
		dispatch(
			requestLogin({
				email,
				password,
			}),
		)
	}

	return (
		<Row className={styles.mainBlock}>
			<Col md={6} lg={8}>
				<Hidden xs sm>
					<BackgroundImage />
				</Hidden>
			</Col>
			<Col md={6} lg={4} className={styles.leftContainer}>
				<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
					<img src={LogoImage} alt='' className={styles.logoHeader} />
					<Font
						fontText='Admin Console'
						className={styles.titleHeader}
						fontAlias='H1'
					/>
					<div className={styles.spacingTopBtm} />
					<Input
						name='email'
						type='email'
						label='Email'
						labelStyle={styles.labelColor}
						htmlFor='email'
						inputStyle={styles.inputBoarder}
						inputRef={register({
							required: 'Email field is required',
						})}
						errorText={errors.email && errors.email.message}
					/>
					<Input
						name='password'
						type='password'
						label='Password'
						labelStyle={styles.labelColor}
						htmlFor='password'
						inputStyle={styles.inputBoarder}
						inputRef={register({
							required: 'Password field is required',
						})}
						isPassword
						errorText={
							(errors.password && errors.password.message) ||
							appError
						}
					/>
					<Link
						to='/auth/forgotpassword'
						className={styles.forgotPassLabel}
					>
						Forgot Password?
					</Link>

					<button type='submit' className={styles.loginButton}>
						{appState === AppState.LOADING ? (
							<LoadSvg className={styles.spinner} svg={Loader} />
						) : (
							<img src={LogoButton} alt='' />
						)}
					</button>
				</form>
			</Col>
		</Row>
	)
}

export default LoginPage
