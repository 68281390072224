/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import moment from 'moment'
import { isEmpty } from 'lodash'

export const transformStories = (stories) => {
	return stories.map((story) => {
		let date = moment(story.published_date) || ''
		if (date) {
			date = date.format('DD MMMM YYYY')
		}
		return {
			id: story.id,
			anonymous: story.anonymous_user,
			isViewed: story.admin_visited,
			isEdited: story.edited,
			user: {
				id: story.user?.id || 0,
				name: story.user?.userprofile?.full_name || '',
				avatar: story.user?.userprofile?.picture || '',
			},
			title: story.title,
			image: story.thumbnail,
			date,
		}
	})
}

export const transformPagination = ({ count, next, previous }) => {
	return {
		next,
		previous,
		count,
	}
}

export const transformStory = (story) => {
	if (isEmpty(story)) {
		return {}
	}

	const verifications = []

	const markSafe = story.story_admin_action.some(
		({ action }) => action === 'Mark As Safe',
	)

	if (!isEmpty(story.verified_objects)) {
		story.verified_objects.forEach((verStory) => {
			verifications.push({
				image: verStory.thumbnail,
				content: verStory.content,
				id: verStory.id,
				user: {
					username: verStory.user.userprofile.full_name,
					avatar: verStory.user.userprofile.picture,
					id: verStory.user.id,
					lat: verStory.latitude,
					lng: verStory.longitude,
				},
			})
		})
	}

	return {
		id: story.id,
		mediaUrl: story.media,
		mediaType: story.media_type,
		location: {
			address: story.location.complete_address,
			lat: story.location.latitude,
			lng: story.location.longitude,
		},
		anon: story.anonymous_user,
		user: {
			name: story.user?.userprofile?.full_name || '',
			picture: story.user?.userprofile?.picture || '',
			lat: story.user_lat,
			lng: story.user_lng,
			id: story.user.id,
		},
		date: story.published_date || '',
		title: story.title,
		description: story.description || '',
		likes: story.reaction_count,
		comments: story.comment_count,
		share: story.share_count,
		active: story.status === 'Publish',
		verifications,
		markSafe,
	}
}

export const transformCommentQuery = (detail) => {
	return {
		commentPage: detail.commentPage,
		commentLoading: detail.commentLoading,
		canGoNext: detail.canGoNext,
		commentCount: detail.commentCount,
	}
}

export const transformComments = (comments) => {
	if (isEmpty(comments)) {
		return []
	}

	return comments.map((comment) => {
		return {
			id: comment.id,
			user: {
				id: comment.user.id,
				name: comment.user.userprofile.full_name,
				picture: comment.user.userprofile.picture,
			},
			description: comment.content,
			likes: comment.comment_reaction_count,
			date: comment.created_at,
			status: comment.status,
		}
	})
}
