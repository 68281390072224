import React from 'react'

import styles from './index.module.css'
// import LogoImage from '../../images/logo.png'
import LoginBackground from '../../images/loginimage.png'

const BackgroundImage = () => {
	return (
		<div className={styles.container}>
			<img src={LoginBackground} alt='' className={styles.mainImage} />
		</div>
	)
}

export default BackgroundImage
