import { isEmpty } from 'lodash'
import moment from 'moment'

export const transformContributions = (list) => {
	if (isEmpty(list)) {
		return []
	}

	return list.map((contribution) => {
		return {
			description: contribution.description,
			id: contribution.id,
			picture: contribution.image || '',
			month: moment()
				.month(contribution.month - 1)
				.format('MMMM'),
			year: contribution.year,
		}
	})
}

export const transformQuery = (list) => {
	return {
		count: list.count,
		next: list.next,
		previous: list.previous,
		currentPage: list.currentPage,
		searchTerm: list.searchTerm,
	}
}

export const abc = 'abc'
