/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import styles from './index.module.css'

const SortDataList = ({ activeType, timeClick, screenSize }) => {
	const dateType = (type) => {
		if (type === 'TODAY') {
			const today = moment(moment.now())
			timeClick(
				`${today.year()}-${today.month() + 1}-${today.date()}`,
				`${today.year()}-${today.month() + 1}-${today.date()}`,
				'TODAY',
			)
		} else if (type === 'YESTERDAY') {
			const yesterday = moment(moment.now()).subtract(1, 'days')
			timeClick(
				`${yesterday.year()}-${
					yesterday.month() + 1
				}-${yesterday.date()}`,
				`${yesterday.year()}-${
					yesterday.month() + 1
				}-${yesterday.date()}`,
				'YESTERDAY',
			)
		} else if (type === 'WEEK') {
			const today = moment(moment.now())
			const week = moment(moment.now()).subtract(7, 'days')
			timeClick(
				`${week.year()}-${week.month() + 1}-${week.date()}`,
				`${today.year()}-${today.month() + 1}-${today.date()}`,
				'WEEK',
			)
		} else {
			timeClick('', '', 'ALL')
		}
	}

	const calcClass = () => {
		if (screenSize === 'lg' || screenSize === 'md' || screenSize === 'xl') {
			return styles.sortDataMd
		}
		return styles.sortDataSm
	}

	return (
		<ul className={calcClass()}>
			<li
				onClick={() => {
					dateType('ALL')
				}}
				className={`${
					activeType === 'ALL' ? styles.selectedData : ''
				} ${styles.fontSize}`}
			>
				All
			</li>
			<li
				className={`${
					activeType === 'TODAY' ? styles.selectedData : ''
				} ${styles.fontSize}`}
				onClick={() => {
					dateType('TODAY')
				}}
			>
				Today
			</li>
			<li
				className={`${
					activeType === 'YESTERDAY' ? styles.selectedData : ''
				} ${styles.fontSize}`}
				onClick={() => {
					dateType('YESTERDAY')
				}}
			>
				Yesterday
			</li>
			<li
				className={`${
					activeType === 'WEEK' ? styles.selectedData : ''
				} ${styles.fontSize}`}
				onClick={() => {
					dateType('WEEK')
				}}
			>
				This Week
			</li>
		</ul>
	)
}

SortDataList.propTypes = {
	activeType: PropTypes.string,
	timeClick: PropTypes.func.isRequired,
	screenSize: PropTypes.string.isRequired,
}

SortDataList.defaultProps = {
	activeType: 'ALL',
}

export default SortDataList
