/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useRef, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ArrowUp from '../../images/arrowUp.svg'
import styles from './index.module.css'

const FilterComponent = ({ selectedValue, data, tagClick, full }) => {
	const container = useRef(null)

	const [open, setOpen] = useState(false)

	const handleClickOutside = useCallback(
		(event) => {
			if (
				container.current &&
				!container.current.contains(event.target)
			) {
				if (open) {
					setOpen(false)
					if (selectedValue) tagClick('')
				}
			}
		},
		[selectedValue, tagClick, open],
	)

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [handleClickOutside])

	const renderTags = () => {
		return data.map(({ id, name }) => {
			return (
				<p
					className={styles.dropDownItem}
					key={`tag-${id}`}
					onClick={() => {
						tagClick(name)
						setOpen(false)
					}}
				>
					{name}
				</p>
			)
		})
	}

	const dropDownClasses = classnames({
		[styles.dropDownContainer]: true,
		[styles.dropDownContainerSm]: full,
		[styles.dropDownContainerInactive]: !open,
		[styles.dropDownContainerActive]: open,
	})

	const dropDownIconClasses = classnames({
		[styles.dropDownIcon]: true,
		[styles.dropDownIconRotate]: open,
	})

	const filterContainer = classnames({
		[styles.filterContainer]: true,
		[styles.filterContainerSm]: full,
	})

	return (
		<div className={filterContainer}>
			<h3>FILTER BY:</h3>
			<div
				className={dropDownClasses}
				ref={container}
				role='tabpanel'
				onClick={() => {
					setOpen((n) => !n)
				}}
			>
				{selectedValue === '' ? (
					<span>Select a tag</span>
				) : (
					<span>{selectedValue}</span>
				)}
				<button className={styles.dropDownButton} type='button'>
					<img
						src={ArrowUp}
						alt='Arrow indicator'
						className={dropDownIconClasses}
					/>
				</button>
				{open && (
					<div className={styles.dropDownList}>{renderTags()}</div>
				)}
			</div>
		</div>
	)
}

FilterComponent.propTypes = {
	selectedValue: PropTypes.string.isRequired,
	data: PropTypes.array.isRequired,

	tagClick: PropTypes.func.isRequired,

	full: PropTypes.bool,
}

FilterComponent.defaultProps = {
	full: false,
}

export default FilterComponent
