/* eslint-disable camelcase */
import moment from 'moment'
import { isEmpty } from 'lodash'

export const transformStories = (stories) => {
	return stories.map((story) => {
		let date = moment(story.created_at) || ''
		if (date) {
			date = date.format('DD MMMM YYYY')
		}
		console.log(story)
		return {
			id: story.id,
			storyId: story.reported_story,
			title: story.title,
			anonymous: story.anonymous_user,
			isViewed: story.admin_visited,
			isEdited: story.edited,
			user: {
				id: story.user?.id || 0,
				name: story.user?.userprofile?.full_name || '',
				avatar: story.user?.userprofile?.picture || '',
			},
			reportCount: story.story_reported_count,
			image: story.thumbnail,
			date,
		}
	})
}

export const transformStory = (story) => {
	if (isEmpty(story)) {
		return {}
	}

	const markSafe = story.story_admin_action.some(
		({ action }) => action === 'Mark As Safe',
	)

	return {
		id: story.id,
		mediaUrl: story.media,
		mediaType: story.media_type,
		location: {
			address: story.location.complete_address,
			lat: story.location.latitude,
			lng: story.location.longitude,
		},
		anon: story.anonymous_user,
		user: {
			id: story.user?.id || 0,
			name: story.user?.userprofile?.full_name || '',
			picture: story.user?.userprofile?.picture || '',
			lat: story.user_lat,
			lng: story.user_lng,
		},
		date: story.published_date || '',
		title: story.title,
		description: story.description || '',
		likes: story.reaction_count,
		comments: story.comment_count,
		share: story.share_count,
		active: story.status === 'Publish',
		markSafe,
	}
}

export const transformPagination = ({ count, next, previous }) => {
	return {
		next,
		previous,
		count,
	}
}

export const transformComments = (comments) => {
	if (isEmpty(comments)) return []

	return comments.map((comment) => {
		return {
			id: comment.id,
			commentId: comment.reported_comment,
			commentStatus: comment.status,
			user: {
				id: comment.user.id,
				name: comment.user.userprofile.full_name,
				picture: comment.user.userprofile.picture || '',
			},
			content: comment.content,
			reportCount: comment.comment_reported_count,
		}
	})
}

export const transformUsers = (users) => {
	if (isEmpty(users)) return []
	return users.map((user) => {
		return {
			id: user.id,
			userId: user.reported_user,
			fullName: user.full_name,
			email: user.email || '',
			username: user.username,
			isActive: user.is_active,
			picture: user.profile_picture,
		}
	})
}

export const transformUser = (user) => {
	if (isEmpty(user)) {
		return {}
	}

	return {
		id: user.id,
		name: user.userprofile.full_name,
		picture: user.userprofile.picture || '',
		mobile: user.userprofile.mobile,
		email: user.email || '',
		isActive: user.is_active,
		totalPosts: user.post,
	}
}

export const transformReportedUser = (reports) => {
	if (isEmpty(reports)) {
		return []
	}

	return reports.map((r) => {
		return {
			id: r.id,
			user: {
				id: r.user.id,
				mobile: r.user.name,
				name: r.user.userprofile.full_name || '',
				picture: r.user.userprofile.picture || '',
			},
			reportTag: r.report_tag,
		}
	})
}

export const transformCommentQuery = (detail) => {
	return {
		commentPage: detail.commentPage,
		commentLoading: detail.commentLoading,
		canGoNext: detail.canGoNext,
		commentCount: detail.commentCount,
	}
}

export const transformStoryComments = (comments) => {
	if (isEmpty(comments)) {
		return []
	}

	return comments.map((comment) => {
		return {
			id: comment.id,
			user: {
				id: comment.user.id,
				name: comment.user.userprofile.full_name,
				picture: comment.user.userprofile.picture,
			},
			description: comment.content,
			likes: comment.comment_reaction_count,
			date: comment.created_at,
			status: comment.status,
		}
	})
}
