import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'

import styles from '../index.module.css'
import { uploadToS3 } from '../../../../utils'
import { Modal, Input, Font, Button } from '../../../../components'

function EditModal({ visible, editClose, selectedAdmin, editSubmitted }) {
	const { register, handleSubmit, errors, setValue } = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
	})
	const [s3Location, setS3Location] = useState('')
	const [loadingImage, setLoadingImage] = useState(false)

	useEffect(() => {
		if (selectedAdmin) {
			setValue('name', selectedAdmin.name)
			setValue('email', selectedAdmin.email)
		}
	}, [selectedAdmin, setValue])

	const imageFile = (e) => {
		setLoadingImage(true)
		uploadToS3(e.target.files[0]).then((data) => {
			setS3Location(data.location)
			setLoadingImage(false)
		})
	}

	const onSubmit = (data) => {
		console.log(s3Location)
		editSubmitted({
			...data,
			picture: s3Location,
		})
	}
	const onHideModal = () => {
		editClose()
	}
	return (
		<Modal visible={visible} closeModal={editClose}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div>
					<Font
						fontText='Edit Detail'
						fontAlias='H2'
						className={styles.modalTitle}
					/>

					<button
						type='button'
						onClick={onHideModal}
						className={styles.modalCross}
					>
						X
					</button>
				</div>

				<br />
				<Input
					name='name'
					label='Name'
					htmlFor='name'
					type='text'
					inputRef={register({
						required: 'Name field is required',
					})}
					errorText={errors.name && errors.name.message}
				/>
				<Input
					name='email'
					label='Email Address'
					htmlFor='email'
					type='email'
					inputRef={register({
						required: 'Email field is required',
					})}
					errorText={errors.email && errors.email.message}
				/>
				<Input
					name='password'
					label='Password'
					htmlFor='password'
					type='password'
					isPassword
					inputRef={register}
				/>
				<div className={styles.bottomModal}>
					<input
						type='file'
						name='myImage'
						accept='image/x-png,image/jpeg'
						onChange={imageFile}
						className={styles.fileInput}
					/>
					<Button
						buttonAlias='Primary'
						buttonText='Save'
						disabled={loadingImage}
						loading={loadingImage}
					/>
				</div>
			</form>
		</Modal>
	)
}

EditModal.propTypes = {
	visible: PropTypes.bool.isRequired,
	selectedAdmin: PropTypes.any,

	editClose: PropTypes.func.isRequired,
	editSubmitted: PropTypes.func.isRequired,
}

EditModal.defaultProps = {
	selectedAdmin: null,
}

export default EditModal
