import React from 'react'
import { Col } from 'react-grid-system'
import PropTypes from 'prop-types'

import styles from './Media.module.css'

const StoryImage = ({ type, media }) => {
	if (type === 'Video') {
		return (
			<Col md={5} sm={12} className={styles.imgContainer}>
				<video
					width='320'
					height='240'
					controls
					autoPlay
					muted
					controlsList='nodownload picture-in-picture'
					className={styles.img}
				>
					<source src={media} type='video/mp4' />
					Your browser does not support the video tag.
				</video>
			</Col>
		)
	}

	return (
		<Col md={5} sm={12} className={styles.imgContainer}>
			<img src={media} className={styles.img} alt='' />
		</Col>
	)
}

StoryImage.propTypes = {
	type: PropTypes.string.isRequired,
	media: PropTypes.string.isRequired,
}

export default StoryImage
