import { createSelector } from 'reselect'

import { transformContributions, transformQuery } from './transform'

export const selectAppState = createSelector(
	(state) => state.contributions,
	(contributions) => contributions.appState,
)

export const selectContributions = createSelector(
	(state) => state.contributions,
	(contributions) => transformContributions(contributions.list.data),
)
export const selectQuery = createSelector(
	(state) => state.contributions,
	(contributions) => transformQuery(contributions.list),
)

export const selectError = createSelector(
	(state) => state.contributions,
	(contributions) => contributions.hasError,
)
