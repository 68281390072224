import { createSelector } from 'reselect'
import {
	transformUsers,
	transformStories,
	transformStory,
	transformComments,
	transformReportedUser,
	transformCommentQuery,
	transformUser,
	transformStoryComments,
} from './transform'

export const selectAppState = createSelector(
	(state) => state.reports,
	(reports) => reports.appState,
)

export const selectQuery = createSelector(
	(state) => state.reports,
	(reports) => reports.query,
)

export const selectStories = createSelector(
	(state) => state.reports,
	(reports) => transformStories(reports.stories.list),
)

export const selectStory = createSelector(
	(state) => state.reports,
	(reports) => transformStory(reports.stories.story),
)

export const selectComments = createSelector(
	(state) => state.reports,
	(reports) => transformComments(reports.comments.list),
)

export const selectUsers = createSelector(
	(state) => state.reports,
	(reports) => transformUsers(reports.users.list),
)

export const selectUser = createSelector(
	(state) => state.reports,
	(reports) => transformUser(reports.users.user),
)

export const selectReportedBy = createSelector(
	(state) => state.reports,
	(reports) => transformReportedUser(reports.reportedBy),
)

export const selectStoryCommentQuery = createSelector(
	(state) => state.reports,
	(reports) => transformCommentQuery(reports.storyComments),
)

export const selectStoryCommentList = createSelector(
	(state) => state.reports,
	(reports) => transformStoryComments(reports.storyComments.list),
)
