/* eslint-disable import/prefer-default-export */
const domain = 'admins'

export const AdminsActions = {
	requestAdmins: `${domain}/requestAdmins`,
	setAdmins: `${domain}/setAdmins`,

	setCurrentPage: `${domain}/setCurrentPage`,

	requestChangeAdminStatus: `${domain}/requestChangeAdminStatus`,

	requestChangeAdmin: `${domain}/requestChangeAdmin`,
	setChangeAdmin: `${domain}/setChangeAdmin`,

	requestAddModerator: `${domain}/requestAddModerator`,

	setAppState: `${domain}/setAppState`,

	setError: `${domain}/setError`,
	clearError: `${domain}/clearError`,
}
