/* eslint-disable import/prefer-default-export */
import { StoriesActions } from './constants'

export const setLoading = (state) => ({
	type: StoriesActions.setLoading,
	payload: state,
})

export const requestStories = () => ({ type: StoriesActions.requestStories })

export const setStories = (payload) => ({
	type: StoriesActions.setStories,
	payload,
})

export const setBuildQuery = (payload) => ({
	type: StoriesActions.setBuildQuery,
	payload,
})

export const requestGetStory = (payload) => ({
	type: StoriesActions.requestGetStory,
	payload,
})

export const setStory = (payload) => ({
	type: StoriesActions.setStory,
	payload,
})

export const requestStoryComments = (payload) => ({
	type: StoriesActions.requestStoryComments,
	payload,
})

export const setStoryCommentsQuery = (payload) => ({
	type: StoriesActions.setStoryCommentsQuery,
	payload,
})

export const setStoryCommentsList = (payload) => ({
	type: StoriesActions.setStoryCommentsList,
	payload,
})

export const resetStoryDetail = () => ({
	type: StoriesActions.resetStoryDetail,
})

export const requestHideStory = (payload) => ({
	type: StoriesActions.requestHideStory,
	payload,
})

export const setHideStory = (payload) => ({
	type: StoriesActions.setHideStory,
	payload,
})

export const requestUnHideStory = (payload) => ({
	type: StoriesActions.requestUnHideStory,
	payload,
})

export const setUnHideStory = (payload) => ({
	type: StoriesActions.setUnHideStory,
	payload,
})

export const requestStoryMarkSafe = (payload) => ({
	type: StoriesActions.requestStoryMarkSafe,
	payload,
})

export const setStoryMarkSafe = (payload) => ({
	type: StoriesActions.setStoryMarkSafe,
	payload,
})

export const requestTags = () => ({
	type: StoriesActions.requestTags,
})

export const setTags = (payload) => ({
	type: StoriesActions.setTags,
	payload,
})

export const requestHideUnhideStoryComments = (payload) => ({
	type: StoriesActions.requestHideUnhideStoryComments,
	payload,
})

export const setHideUnhideStoryComments = (payload) => ({
	type: StoriesActions.setHideUnhideStoryComments,
	payload,
})
