/* eslint-disable no-alert */
/* eslint-disable no-console */
import { all, takeLatest, call, put } from 'redux-saga/effects'

import { AuthActions } from './constants'
import {
	setUpAxios,
	apiLogin,
	apiForgotPassword,
	apiResetPassword,
} from '../../store/api'
import { setUser } from '../../store/main/actions'
import { setToken, routerPush } from '../../utils'
import { setError, setAppState } from './actions'
import { AppState } from '../../store/constants'

function* requestLogin({ payload }) {
	try {
		yield put(setAppState(AppState.LOADING))
		const formData = new FormData()
		formData.append('username', payload.email)
		formData.append('password', payload.password)

		const res = yield call(apiLogin, formData)

		const { data, auth } = res.data

		yield call(setToken, auth.access_token)
		yield call(setUpAxios)
		yield put(
			setUser({
				id: data.id,
				token: auth.access_token,
				firstName: data.first_name,
				lastName: data.last_name,
				fullName: data.full_name,
				picture: data.picture,
				superUser: data.is_superuser,
			}),
		)
		routerPush('/')
	} catch (err) {
		yield put(setError('Invalid password or email'))
		console.log('Login :', err)
	} finally {
		yield put(setAppState(AppState.READY))
	}
}

function* requestForgotPassword({ payload }) {
	try {
		yield put(setAppState(AppState.LOADING))
		const url = `${window.location.origin}/auth/resetpassword/`
		const formData = new FormData()
		formData.append('username', payload)
		formData.append('link', url)
		yield call(apiForgotPassword, formData)
		alert(`Email has been sent to ${payload}`)
		// window.location.href = res.data.link
	} catch (err) {
		yield put(setError('User with this email does not exist'))
		console.log('Forgot Password :', err)
	} finally {
		yield put(setAppState(AppState.READY))
	}
}

function* requestResetPassword({ payload }) {
	try {
		yield put(setAppState(AppState.LOADING))
		const formData = new FormData()
		formData.append('password', payload.password)
		formData.append('confirm_password', payload.password)
		formData.append('token', payload.token)
		yield call(apiResetPassword, formData)
		const forward = `${window.location.origin}/auth/success`
		window.location.href = forward
	} catch (err) {
		yield put(setError('Invalid Token Error'))
		console.log('Reset Password: ', err)
	} finally {
		yield put(setAppState(AppState.READY))
	}
}

export default function* () {
	yield all([
		yield takeLatest(AuthActions.requestLogin, requestLogin),
		yield takeLatest(
			AuthActions.requestForgotPassword,
			requestForgotPassword,
		),
		yield takeLatest(
			AuthActions.requestResetPassword,
			requestResetPassword,
		),
	])
}
