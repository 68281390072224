import { AppState } from '../../store/constants'
import { Actions } from './constants'

const initialState = {
	appState: AppState.IDLE,

	list: [],
	user: {},
	stories: [],

	query: {
		next: null,
		previous: null,
		currentPage: 1,
		count: 0,
		searchTerm: '',
	},
}

export default (state = initialState, action) => {
	const { type, payload } = action

	switch (type) {
		case Actions.setAppState: {
			return {
				...state,
				appState: payload,
			}
		}
		case Actions.setQuery: {
			return {
				...state,
				query: {
					...state.query,
					...payload,
				},
			}
		}
		case Actions.clearQuery: {
			return {
				...state,
				query: {
					next: null,
					previous: null,
					currentPage: 1,
					count: 0,
					searchTerm: '',
				},
			}
		}
		//
		case Actions.setUsers: {
			return {
				...state,
				list: payload,
			}
		}
		//
		case Actions.setHideUser: {
			const users = state.list.map((user) => {
				if (user.id === payload.id) {
					return {
						...user,
						is_active: payload.is_active,
					}
				}
				return user
			})
			return {
				...state,
				list: users,
				user: {
					...state.user,
					is_active: payload.is_active,
				},
			}
		}
		//
		case Actions.setUser: {
			return {
				...state,
				user: payload,
			}
		}

		case Actions.setStories: {
			return {
				...state,
				stories: payload,
			}
		}

		default: {
			return state
		}
	}
}
