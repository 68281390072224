/* eslint-disable import/prefer-default-export */
const domain = 'contributions'

export const ContributionsActions = {
	requestContributions: `${domain}/requestContributions`,

	requestAddChallenge: `${domain}/requestAddChallenge`,

	setContributions: `${domain}/setContributions`,

	setCurrentPage: `${domain}/setCurrentPage`,

	requestChangeContributionStatus: `${domain}/requestChangeContributionStatus`,

	requestChangeContribution: `${domain}/requestChangeContribution`,

	setChangeContribution: `${domain}/setChangeContribution`,

	requestAddModerator: `${domain}/requestAddModerator`,

	setAppState: `${domain}/setAppState`,

	setError: `${domain}/setError`,

	clearError: `${domain}/clearError`,
}
