import { all, call, takeLatest, put } from 'redux-saga/effects'

import { apiGetProfile, apiChangePassword, clearAxios } from '../api'
import { setUser, setAppState } from './actions'
import { MainActions } from './constants'
import { AppState } from '../constants'
import { getToken, removeToken } from '../../utils'

function* requestUserProfile() {
	try {
		yield put(setAppState(AppState.LOADING))
		const res = yield call(apiGetProfile)
		const { data } = res.data

		yield put(
			setUser({
				id: data.id,
				token: getToken(),
				firstName: data.first_name,
				lastName: data.last_name,
				fullName: data.full_name,
				picture: data.picture,
				superUser: data.is_superuser,
			}),
		)
	} catch (err) {
		yield call(removeToken)
		yield call(clearAxios)
	} finally {
		yield put(setAppState(AppState.READY))
	}
}

function* requestChangePassword({ payload }) {
	try {
		const data = new FormData()
		data.append('password', payload)
		data.append('confirm_password', payload)
		yield call(apiChangePassword, data)
	} catch (err) {
		console.log('requestChangePassword', err)
	}
}

export default function* () {
	yield all([
		yield takeLatest(MainActions.requestUserProfile, requestUserProfile),
		yield takeLatest(
			MainActions.requestChangePassword,
			requestChangePassword,
		),
	])
}
