import React from 'react'
import PropTypes from 'prop-types'

import { LoadSvg } from '../../../../components'
import LikeImage from '../../../../images/like.svg'
import styles from './ReactionSection.module.css'

const ReactionSection = ({ likeCount, reshareCount, commentCount }) => {
	return (
		<div className={styles.reactionContainer}>
			<div className={styles.likeContainer}>
				<LoadSvg svg={LikeImage} />
				<p className={styles.reactionText}>
					<strong>{likeCount}</strong> Likes
				</p>
			</div>
			<div className={styles.reactionTextContainer}>
				<p className={styles.reactionText}>
					<strong>{reshareCount}</strong> Reshares
				</p>
				<p className={styles.reactionText}>
					<strong>{commentCount}</strong> Comments
				</p>
			</div>
		</div>
	)
}

ReactionSection.propTypes = {
	likeCount: PropTypes.number.isRequired,
	reshareCount: PropTypes.number.isRequired,
	commentCount: PropTypes.number.isRequired,
}

export default ReactionSection
