import { createSelector } from 'reselect'

export const selectAuthStatus = createSelector(
	(state) => state.main,
	(main) => main.isAuthenticated,
)

export const selectAppState = createSelector(
	(state) => state.main,
	(main) => main.appState,
)

export const selectSuperUser = createSelector(
	(state) => state.main,
	(main) => main.user.superUser,
)
