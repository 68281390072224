import { AuthActions } from './constants'

export const requestLogin = (payload) => ({
	type: AuthActions.requestLogin,
	payload,
})

export const requestForgotPassword = (payload) => ({
	type: AuthActions.requestForgotPassword,
	payload,
})

export const requestResetPassword = (payload) => ({
	type: AuthActions.requestResetPassword,
	payload,
})

export const clearError = (payload) => ({
	type: AuthActions.clearError,
	payload,
})

export const setAppState = (payload) => ({
	type: AuthActions.setAppState,
	payload,
})

export const setError = (payload) => ({
	type: AuthActions.setError,
	payload,
})

export const abc = 'abc'
