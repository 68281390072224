/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'

import PlaceholderAvatar from '../../images/placeholder.png'
import EditPencil from '../../images/edit-pencil.svg'
import styles from './index.module.css'

const ContributionTable = ({ data, selectContribution }) => {
	//
	const setOnErrorImage = (e) => {
		e.currentTarget.src = PlaceholderAvatar
	}
	const renderContributions = () => {
		return data.map((contribution) => {
			// const selectClass = classnames({
			// 	[styles.select]: true,
			// 	[styles.selectRed]: !admin.status,
			// })
			return (
				<tr key={`contribution-${contribution.id}`}>
					<td className={styles.userInfo}>
						<img
							alt='contribution'
							src={contribution.picture || PlaceholderAvatar}
							onError={setOnErrorImage}
							className={styles.userImage}
						/>
						<div className={styles.userName}>
							{contribution.description}
						</div>
					</td>

					<td>{contribution.month}</td>
					<td>{contribution.year}</td>

					{/* <td>
						<select
							onChange={(e) => {
								const val = e.target.value === 'enabled'
								selectChange(admin.id, val)
							}}
							className={selectClass}
							defaultValue={admin.status ? 'enabled' : 'disabled'}
						>
							<option
								value='enabled'
								className={styles.selectBlack}
							>
								Enabled
							</option>
							<option
								className={styles.selectRed}
								value='disabled'
							>
								Blocked
							</option>
						</select>
					</td> */}
					<td
						onClick={() => selectContribution(contribution)}
						className={styles.editButton}
					>
						<img alt='Edit Pencil' src={EditPencil} />
						Edit
					</td>
				</tr>
			)
		})
	}

	return (
		<div className={styles.horizontalScroll}>
			<table className={styles.userData}>
				<thead>
					<tr>
						<th>Contribution</th>
						<th>Month</th>
						<th>Year</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>{renderContributions()}</tbody>
			</table>
		</div>
	)
}

ContributionTable.propTypes = {
	data: PropTypes.array.isRequired,
	selectContribution: PropTypes.func.isRequired,
}

export default ContributionTable
