import { AppState } from '../../store/constants'
import { ContributionsActions } from './constants'

const initialState = {
	appState: AppState.IDLE,

	hasError: false,

	list: {
		data: [],
		count: 0,
		next: null,
		previous: null,
		currentPage: 1,
		searchTerm: '',
	},
}

export default (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case ContributionsActions.setAppState: {
			return {
				...state,
				appState: payload,
			}
		}
		case ContributionsActions.setContributions: {
			return {
				...state,
				list: {
					...state.list,
					data: payload.list,
					count: payload.count,
					next: payload.next,
					previous: payload.previous,
				},
			}
		}
		case ContributionsActions.setCurrentPage: {
			return {
				...state,
				list: {
					...state.list,
					searchTerm: payload.searchTerm,
					currentPage: payload.currentPage,
				},
			}
		}
		default: {
			return state
		}
	}
}
