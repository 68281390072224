import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Row, Col } from 'react-grid-system'
// import {
// 	useDispatch
// } from 'react-redux'
import PropTypes from 'prop-types'
// import { useForm } from 'react-hook-form'

// import { selectPicture } from './selector'
import // setLogout,
// requestChangePassword
'../../store/main/actions'
// import Modal from '../Modal'
// import Input from '../Input'
// import Font from '../Font'
// import Button from '../Button'

import styles from './index.module.css'
import searchIcon from '../../images/icons/search.png'
// import PlaceholderIcon from '../../images/placeholder.png'

const SearchComponent = ({ doSearch, value }) => {
	// const dispatch = useDispatch()
	const [searchText, setSearchText] = useState(value)
	const [openMenu, setOpenMenu] = useState(false)
	// const [
	// 	// openModal,
	// 	// setOpenModal
	// ] = useState(false)
	const container = useRef(null)

	// const {
	// 	register,
	// 	handleSubmit,
	// 	errors,
	// 	watch
	// } = useForm({
	// 	mode: 'onBlur',
	// 	reValidateMode: 'onBlur',
	// })

	// const passwordMatchError =
	// 	watch('password') !== watch('confirmPassword')
	// 		? 'Password fields did not match'
	// 		: ''

	// const onSubmit = (data) => {
	// 	if (!passwordMatchError) {
	// 		dispatch(requestChangePassword(data.password))
	// 		closeModal()
	// 	}
	// }

	useEffect(() => {
		setSearchText(value)
	}, [value])

	const handleClickOutside = useCallback(
		(event) => {
			if (
				container.current &&
				!container.current.contains(event.target)
			) {
				if (setOpenMenu) {
					setOpenMenu(false)
				}
			}
		},
		[openMenu],
	)

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		document.addEventListener('scroll', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
			document.removeEventListener('scroll', handleClickOutside)
		}
	}, [handleClickOutside])

	// const picture = useSelector(selectPicture)

	const onChange = (e) => {
		setSearchText(e.target.value)
		doSearch(e.target.value.toLowerCase())
	}

	// const logout = () => {
	// 	dispatch(setLogout())
	// 	window.location.href = '/auth'
	// }

	// const closeModal = () => {
	// 	setOpenModal(false)
	// }

	// const setOnErrorImage = (e) => {
	// 	e.currentTarget.src = PlaceholderIcon
	// }

	return (
		<>
			<Row>
				<Col md={12}>
					<div className={styles.wrapperClass}>
						<div className={styles.searchWrapper}>
							<button
								type='button'
								className={styles.icon}
								onClick={() => {
									if (searchText !== '') {
										doSearch(searchText.toLowerCase())
									}
								}}
							>
								<img
									alt='click here to search the story'
									src={searchIcon}
								/>
							</button>
							<input
								type='text'
								className={styles.inputSearch}
								placeholder='Search'
								onChange={onChange}
								value={searchText}
							/>
						</div>
						{/* <button
							type='button'
							className={styles.avatarButton}
							onClick={() => {
								setOpenMenu((n) => !n)
							}}
						>
							<img
								alt='Users'
								src={picture || PlaceholderIcon}
								onError={setOnErrorImage}
								className={styles.avatar}
							/>
							{openMenu && (
								<div className={styles.menu} ref={container}>
									<button type='button' onClick={logout}>
										Logout
									</button>
									<button
										type='button'
										onClick={() => {
											setOpenModal((n) => !n)
										}}
									>
										Change Password
									</button>
								</div>
							)}
						</button> */}
					</div>
				</Col>
			</Row>
			{/* <Modal visible={openModal} closeModal={closeModal}>
				<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
					<div className={styles.formTop}>
						<Font fontText='Change Password' fontAlias='H2' />
						<button type='button' onClick={closeModal}>
							&times;
						</button>
					</div>
					<br />
					<Input
						name='password'
						label='New Password'
						htmlFor='password'
						type='password'
						inputRef={register({
							required: 'Password field is required',
						})}
						errorText={errors.password && errors.password.message}
					/>
					<Input
						name='confirmPassword'
						label='Confirm New Password'
						htmlFor='confirmPassword'
						type='password'
						inputRef={register({
							required: 'Confirm Password field is required',
						})}
						errorText={
							(errors.confirmPassword &&
								errors.confirmPassword.message) ||
							passwordMatchError
						}
					/>
					<br />
					<Button buttonAlias='Primary' buttonText='Save' />
				</form>
			</Modal> */}
		</>
	)
}

SearchComponent.propTypes = {
	value: PropTypes.string.isRequired,
	doSearch: PropTypes.func.isRequired,
}

export default SearchComponent
