export default (title, trimLength) => {
	const one = trimLength - 1
	const two = trimLength - 2
	const three = trimLength - 3
	if (title.length > trimLength) {
		const text = title.slice(0, trimLength).split('')
		text[three] = '.'
		text[two] = '.'
		text[one] = '.'
		return text.join('')
	}
	return title
}
