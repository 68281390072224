import { uploadFile } from 'react-s3'

export default (file) => {
	return new Promise((res, rej) => {
		const config = {
			bucketName: process.env.REACT_APP_BUCKET_NAME,
			dirName: 'uploads',
			region: 'us-east-1',
			accessKeyId: process.env.REACT_APP_BUCKET_ACCESSKEYID,
			secretAccessKey: process.env.REACT_APP_BUCKET_SECRETACCESSKEY,
		}
		const blob = file.slice(0, file.size, 'image/png')
		const newFile = new File(
			[blob],
			`${new Date().getTime()}${file.name}`,
			{ type: 'image/png' },
		)
		uploadFile(newFile, config)
			.then((data) => {
				res(data)
			})
			.catch((err) => {
				rej(err)
			})
	})
}
