import React from 'react'
import { useHistory, Link } from 'react-router-dom'

import styles from './index.module.css'

const Header = () => {
	const {
		location: { pathname },
	} = useHistory()

	return (
		<ul className={styles.reportTabs}>
			<li
				className={
					pathname === '/reports/stories' ? styles.activeTab : ''
				}
			>
				<Link to='/reports/stories'>Stories</Link>
			</li>
			<li
				className={
					pathname === '/reports/comments' ? styles.activeTab : ''
				}
			>
				<Link to='/reports/comments'>Comments</Link>
			</li>
			<li
				className={
					pathname === '/reports/users' ? styles.activeTab : ''
				}
			>
				<Link to='/reports/users'>Users</Link>
			</li>
		</ul>
	)
}

export default Header
