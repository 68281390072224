import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import { useHistory } from 'react-router-dom'
import { isEmpty, debounce } from 'lodash'

import {
	Spinner,
	Font,
	Search,
	Pagination,
	UserTable,
} from '../../../components'
import { AppState } from '../../../store/constants'
import { useQuery } from '../../../utils'

import { requestUsers, setQuery, requestHideUser } from '../actions'
import { selectQuery, selectAppState, selectUsers } from '../selector'
import Header from '../helpers/Header'

const User = () => {
	const dispatch = useDispatch()
	const query = useSelector(selectQuery)
	const appState = useSelector(selectAppState)
	const users = useSelector(selectUsers)
	const pageQuery = useQuery()
	const history = useHistory()

	useEffect(() => {
		const currentPage = Number(pageQuery.get('page')) || 1
		const searchTerm = pageQuery.get('full_name') || ''

		dispatch(
			setQuery({
				...query,
				currentPage,
				searchTerm,
			}),
		)
		dispatch(requestUsers())
		return () => {
			dispatch(
				setQuery({
					next: null,
					previous: null,
					currentPage: 1,
					count: 0,
					searchTerm: '',
				}),
			)
		}
	}, [dispatch])

	useEffect(() => {
		let buildQuery = `?page=${query.currentPage || 1}`

		buildQuery = query.searchTerm
			? `${buildQuery}&full_name=${query.searchTerm}`
			: buildQuery

		history.replace(`/reports/users${buildQuery}`)
	}, [query])

	if (appState === AppState.IDLE || appState === AppState.LOADING) {
		return <Spinner />
	}

	const paginatorClick = (type) => {
		if (type === 'NEXT') {
			dispatch(
				setQuery({
					currentPage: query.currentPage + 1,
				}),
			)
		} else if (type === 'PREV') {
			dispatch(
				setQuery({
					currentPage: query.currentPage - 1,
				}),
			)
		} else {
			dispatch(
				setQuery({
					currentPage: type,
				}),
			)
		}
		dispatch(requestUsers())
	}

	const doSearch = debounce((term) => {
		dispatch(
			setQuery({
				...query,
				searchTerm: term,
			}),
		)
		dispatch(requestUsers())
	}, 500)

	const selectChange = (id, status) => {
		const isActive = status === 'enabled'
		dispatch(
			requestHideUser({
				id,
				isActive,
			}),
		)
	}

	return (
		<>
			<Search doSearch={doSearch} value={query.searchTerm} />
			<Container>
				<Row>
					<Col sm={12} mg={12} lg={12}>
						<Header />
					</Col>
				</Row>
				<Row>
					<Col>
						{isEmpty(users) ? (
							<Font
								fontAlias='H2'
								fontText='Currently has no reported users'
							/>
						) : (
							<UserTable
								data={users}
								selectChange={selectChange}
							/>
						)}
					</Col>
				</Row>
				{!isEmpty(users) && (
					<>
						<Row>
							<Col sm={12}>
								<Pagination
									next={query.next}
									previous={query.previous}
									onClick={paginatorClick}
									totalCount={query.count}
									perPageCount={10}
									activePage={query.currentPage}
								/>
							</Col>
						</Row>
						<br />
						<br />
					</>
				)}
			</Container>
		</>
	)
}

export default User
