import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { Row, Col, Hidden } from 'react-grid-system'
import { useSelector } from 'react-redux'

import { routerRef } from './utils'
import { Sidebar, NotFound, MobileHeader, UserIcon } from './components'
import { selectSuperUser } from './store/main/selector'

import StoryDetail from './domains/stories/Detail'
import Stories from './domains/stories/Stories'
import Admins from './domains/admins/Admins'

import ReportedStories from './domains/reports/Stories'
import ReportedComments from './domains/reports/Comments'
import ReportedUsers from './domains/reports/Users'
import ReportedUser from './domains/reports/User'
import ReportedStory from './domains/reports/Story'

import Users from './domains/users/Users'
import User from './domains/users/User'

import Contributions from './domains/contributions/Contributions'

function AuthenticatedApp() {
	const isSuperUser = useSelector(selectSuperUser)
	return (
		<BrowserRouter
			ref={(e) => {
				routerRef.current = e
			}}
		>
			<Row>
				<Hidden xs sm>
					<Col sm={0} md={3} lg={2}>
						<Sidebar isSuperUser={isSuperUser} />
					</Col>
				</Hidden>
				<Hidden md lg xl>
					<MobileHeader isSuperUser={isSuperUser} />
				</Hidden>
				<Col sm={12} md={9} lg={10}>
					<>
						<Hidden xs sm>
							<UserIcon />
						</Hidden>

						{/* <Search /> */}
						<Switch>
							{/* Auth  */}
							<Route exact path='/auth'>
								<Stories />
							</Route>
							{/* Stories  */}
							<Route exact path='/stories/:id'>
								<StoryDetail />
							</Route>
							<Route exact path='/stories'>
								<Stories />
							</Route>
							{/* Reports */}
							<Route exact path='/reports/stories'>
								<ReportedStories />
							</Route>
							<Route exact path='/reports/comments'>
								<ReportedComments />
							</Route>
							<Route exact path='/reports/users'>
								<ReportedUsers />
							</Route>
							<Route exact path='/reports/users/:id'>
								<ReportedUser />
							</Route>
							<Route exact path='/reports/stories/:id'>
								<ReportedStory />
							</Route>
							{/* Users */}
							<Route exact path='/users'>
								<Users />
							</Route>
							<Route exact path='/users/:id'>
								<User />
							</Route>
							{/* Admins */}
							{isSuperUser && (
								<Route exact path='/admins'>
									<Admins />
								</Route>
							)}
							<Route
								exact
								path='/'
								render={() => {
									return <Redirect to='/stories' />
								}}
							/>
							<Route exact path='/contributions'>
								<Contributions />
							</Route>
							<Route path='*'>
								<NotFound />
							</Route>
						</Switch>
					</>
				</Col>
			</Row>
		</BrowserRouter>
	)
}

export default AuthenticatedApp
