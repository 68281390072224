/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import UserIcon from '../UserIcon'

import styles from './index.module.css'

import Logo from '../../images/alertlilogo.svg'
import HamburgerImage from '../../images/hamburger.svg'
import StoriesImage from '../../images/icons/stories.png'
import UserImage from '../../images/icons/user.png'
import ReportImage from '../../images/icons/document.png'

const MobileHeader = ({ isSuperUser }) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const {
		location: { pathname },
	} = useHistory()

	const onClick = () => {
		setIsMenuOpen((n) => !n)
	}

	const closeMenu = () => {
		setIsMenuOpen(false)
	}

	const handleClickOutside = useCallback((event) => {
		if (!event.target.classList.contains('menu-drawer')) {
			closeMenu(false)
		}
	}, [])

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [handleClickOutside])

	return (
		<>
			<div className={styles.container}>
				<button className={styles.menu} onClick={onClick} type='button'>
					<img src={HamburgerImage} alt='Menu' />
				</button>
				<Link to='/' className={styles.logo}>
					<img src={Logo} alt='Alertli header' />
				</Link>
				<UserIcon />
			</div>
			{isMenuOpen && (
				<div className={styles.overlay}>
					<div className={`${styles.drawer} menu-drawer`}>
						<img
							src={Logo}
							alt='Menu logo'
							className={styles.menuLogo}
						/>
						<ul className={styles.menuList}>
							<li
								className={classnames({
									[styles.menuListItem]: true,
									[styles.active]: pathname === '/stories',
								})}
							>
								<img src={StoriesImage} alt='Stories' />
								<Link
									onClick={() => {
										closeMenu()
										window.location.href = '/stories'
									}}
									className='menu-drawer'
									to='/stories'
								>
									Stories
								</Link>
							</li>
							<li
								className={classnames({
									[styles.menuListItem]: true,
									[styles.active]:
										pathname === '/reports/stories' ||
										pathname === '/reports/comments' ||
										pathname === '/reports/users',
								})}
								onClick={() => {
									closeMenu()
									window.location.href =
										'/reports/stories?page=1'
								}}
							>
								<img src={ReportImage} alt='Reports' />
								<Link className='menu-drawer' to='#'>
									Reports
								</Link>
							</li>
							<li
								className={classnames({
									[styles.menuListItem]: true,
									[styles.active]: pathname === '/users',
								})}
								onClick={() => {
									closeMenu()
									window.location.href = '/users?page=1'
								}}
							>
								<img src={UserImage} alt='Users' />
								<Link className='menu-drawer' to='#'>
									Users
								</Link>
							</li>
							{isSuperUser && (
								<li
									className={classnames({
										[styles.menuListItem]: true,
										[styles.active]: pathname === '/admins',
									})}
									onClick={() => {
										closeMenu()
										window.location.href = '/admins?page=1'
									}}
								>
									<img src={UserImage} alt='Admins' />
									<Link className='menu-drawer' to='#'>
										Admins
									</Link>
								</li>
							)}
						</ul>
					</div>
				</div>
			)}
		</>
	)
}

MobileHeader.propTypes = {
	isSuperUser: PropTypes.bool.isRequired,
}

export default MobileHeader
