import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import { useHistory } from 'react-router-dom'
import { isEmpty, debounce } from 'lodash'
import styles from './index.module.css'

import {
	Spinner,
	Font,
	Search,
	Pagination,
	UserTable,
} from '../../../components'
import { AppState } from '../../../store/constants'
import { useQuery } from '../../../utils'

import { requestUsers, setQuery, requestHideUser, clearQuery } from '../actions'
import { selectAppState, selectUsers, selectQuery } from '../selector'

const User = () => {
	const dispatch = useDispatch()
	const pageQuery = useQuery()
	const history = useHistory()
	const appState = useSelector(selectAppState)
	const users = useSelector(selectUsers)
	const query = useSelector(selectQuery)

	useEffect(() => {
		const currentPage = Number(pageQuery.get('page')) || 1
		const searchTerm = pageQuery.get('full_name') || ''

		dispatch(
			setQuery({
				...query,
				currentPage,
				searchTerm,
			}),
		)
		dispatch(requestUsers())
		return () => {
			dispatch(clearQuery())
		}
	}, [dispatch])

	useEffect(() => {
		let buildQuery = `?page=${query.currentPage || 1}`

		buildQuery = query.searchTerm
			? `${buildQuery}&full_name=${query.searchTerm}`
			: buildQuery

		history.replace(`/users${buildQuery}`)
	}, [query])

	if (appState === AppState.IDLE || appState === AppState.LOADING) {
		return <Spinner />
	}

	const paginatorClick = (type) => {
		if (type === 'NEXT') {
			dispatch(
				setQuery({
					currentPage: query.currentPage + 1,
				}),
			)
		} else if (type === 'PREV') {
			dispatch(
				setQuery({
					currentPage: query.currentPage - 1,
				}),
			)
		} else {
			dispatch(
				setQuery({
					currentPage: type,
				}),
			)
		}
		dispatch(requestUsers())
	}

	const doSearch = debounce((term) => {
		dispatch(
			setQuery({
				...query,
				searchTerm: term,
				currentPage: 1,
			}),
		)
		dispatch(requestUsers())
	}, 500)

	const selectChange = (id, status) => {
		const isActive = status === 'enabled'

		dispatch(
			requestHideUser({
				id,
				isActive,
			}),
		)
	}
	return (
		<>
			<Search doSearch={doSearch} value={query.searchTerm} />
			<Container>
				<Row>
					<br />
					<Col sm={6} mg={6} lg={6}>
						<Font fontAlias='H2' fontText='All Users' />
					</Col>
					{isEmpty(users) ? (
						<Col sm={6} mg={6} lg={6}>
							<Font
								fontAlias='H2'
								fontText='Alertli Currently has no registered users'
							/>
						</Col>
					) : (
						<Col sm={6} mg={6} lg={6}>
							<Font
								fontAlias='H4'
								className={styles.textAlignRight}
								fontText={`Signed Up Today (${users[0].signups_today})`}
							/>
							<Font
								fontAlias='H4'
								className={styles.textAlignRight}
								fontText={`Signed Up Yesterday (${users[0].signups_yesterday})`}
							/>
						</Col>
					)}
				</Row>
				<Row>
					<Col>
						{isEmpty(users) ? (
							<Font
								fontAlias='H2'
								fontText='Alertli Currently has no registered users'
							/>
						) : (
							<UserTable
								data={users}
								selectChange={selectChange}
								forwardLink='USERS'
							/>
						)}
					</Col>
				</Row>
				{!isEmpty(users) && (
					<>
						<Row>
							<Col sm={12}>
								<Pagination
									next={query.next}
									previous={query.previous}
									onClick={paginatorClick}
									totalCount={query.count}
									perPageCount={12}
									activePage={query.currentPage}
								/>
							</Col>
						</Row>
						<br />
						<br />
					</>
				)}
			</Container>
		</>
	)
}

export default User
