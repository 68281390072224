import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'

import { Modal, Input, Font, Button } from '../../../../components'
import { uploadToS3 } from '../../../../utils'

import styles from '../index.module.css'

function AddModal({ visible, addClose, addSubmitted }) {
	const { register, handleSubmit, errors } = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
	})

	const [s3Location, setS3Location] = useState('')
	const [loadingImage, setLoadingImage] = useState(false)

	const imageFile = (e) => {
		console.log(e.target.files[0])
		if (!e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
			alert('Please provide Image file')
			return
		}
		setLoadingImage(true)
		uploadToS3(e.target.files[0]).then((data) => {
			setS3Location(data.location)
			setLoadingImage(false)
		})
	}

	const onSubmit = (data) => {
		addSubmitted({
			...data,
			picture: s3Location,
		})
	}

	const onHideModal = () => {
		addClose()
	}

	return (
		<Modal visible={visible} closeModal={addClose}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div>
					<Font
						fontText='Contributions Detail'
						fontAlias='H2'
						className={styles.modalTitle}
					/>
					<button
						type='button'
						onClick={onHideModal}
						className={styles.modalCross}
					>
						X
					</button>
				</div>

				<br />
				<div className={styles.marginBottom16}>
					<div className={styles.month}>
						<label htmlFor='month' className={styles.label}>
							Month
						</label>
						<select
							name='month'
							className={styles.dateSelect}
							ref={register({
								required: 'Text field is required',
							})}
						>
							<option value='1'>January</option>
							<option value='2'>Febuary</option>
							<option value='3'>March</option>
							<option value='4'>April</option>
							<option value='5'>May</option>
							<option value='6'>June</option>
							<option value='7'>July</option>
							<option value='8'>August</option>
							<option value='9'>September</option>
							<option value='10'>October</option>
							<option value='11'>November</option>
							<option value='12'>December</option>
						</select>
					</div>
					<div className={styles.year}>
						<label htmlFor='year' className={styles.label}>
							Year
						</label>
						<select
							name='year'
							className={styles.dateSelect}
							ref={register({
								required: 'Text field is required',
							})}
						>
							<option value='2020'>2020</option>
							<option value='2021'>2021</option>
							<option value='2022'>2022</option>
							<option value='2023'>2023</option>
						</select>
					</div>
				</div>

				<Input
					name='text'
					label='Text'
					htmlFor='text'
					type='text'
					inputRef={register({
						required: 'Text field is required',
					})}
					errorText={errors.text && errors.text.message}
				/>
				<div className={styles.bottomModal}>
					<input
						type='file'
						name='myImage'
						accept='image/x-png,image/jpeg'
						onChange={imageFile}
						className={styles.fileInput}
					/>
					<Button
						buttonAlias='Primary'
						buttonText='Save'
						disabled={loadingImage}
						loading={loadingImage}
					/>
				</div>
			</form>
		</Modal>
	)
}

AddModal.propTypes = {
	visible: PropTypes.bool.isRequired,
	addClose: PropTypes.func.isRequired,
	addSubmitted: PropTypes.func.isRequired,
}

export default AddModal
