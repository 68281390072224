import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container } from 'react-grid-system'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'

import {
	Font,
	Button,
	Search,
	Pagination,
	ContributionTable,
	Spinner,
} from '../../../components'

import {
	requestAddChallenge,
	requestContributions,
	requestChangeContribution,
	setCurrentPage,
	clearError,
} from '../actions'
import {
	selectError,
	selectAppState,
	selectQuery,
	selectContributions,
} from '../selector'

import AddModal from './helpers/AddModal'
import EditModal from './helpers/EditModal'
import { AppState } from '../../../store/constants'

import { useQuery } from '../../../utils'
import styles from './index.module.css'

const Contributions = () => {
	const dispatch = useDispatch()
	const hasError = useSelector(selectError)
	const appState = useSelector(selectAppState)
	const pageQuery = useQuery()
	const history = useHistory()
	const contributions = useSelector(selectContributions)
	const query = useSelector(selectQuery)
	const [addModal, setAddModal] = useState(false)
	const [editModal, setEditModal] = useState(false)
	const [selectedContribution, setSelectedContribution] = useState(null)

	useEffect(() => {
		let buildQuery = `?page=${query.currentPage || 1}`

		buildQuery = query.searchTerm
			? `${buildQuery}&full_name=${query.searchTerm}`
			: buildQuery

		history.replace(`/contributions${buildQuery}`)
	}, [query])

	useEffect(() => {
		const currentPage = Number(pageQuery.get('page')) || 1
		const searchTerm = pageQuery.get('full_name') || ''
		dispatch(
			setCurrentPage({
				...query,
				searchTerm,
				currentPage,
			}),
		)
		dispatch(requestContributions())
	}, [dispatch])

	if (appState === AppState.IDLE || appState === AppState.LOADING) {
		return <Spinner />
	}

	const addSubmitted = (data) => {
		dispatch(
			requestAddChallenge(data, () => {
				setAddModal(false)
				dispatch(clearError())
			}),
		)
	}

	const addClose = () => {
		setAddModal(false)
		dispatch(clearError())
	}

	const editClose = () => {
		setEditModal(false)
		setSelectedContribution(null)
	}

	const editSubmitted = (data) => {
		dispatch(
			requestChangeContribution({
				id: selectedContribution.id,
				description: data.text,
				month: data.month,
				year: data.year,
				picture: data.picture,
			}),
		)
		setEditModal(false)
		setSelectedContribution(null)
	}

	const paginatorClick = (type) => {
		if (type === 'NEXT') {
			//
			dispatch(
				setCurrentPage({
					...query,
					currentPage: query.currentPage + 1,
				}),
			)
		} else if (type === 'PREV') {
			dispatch(
				setCurrentPage({
					...query,
					currentPage: query.currentPage - 1,
				}),
			)
		} else {
			dispatch(
				setCurrentPage({
					...query,
					currentPage: type,
				}),
			)
		}
		dispatch(requestContributions())
	}

	return (
		<>
			<Search />
			<Container>
				<Row>
					<Col
						xs={6}
						sm={6}
						md={6}
						lg={6}
						xl={6}
						className={styles.headingCenter}
					>
						<Font fontAlias='H2' fontText='All Contributions' />
					</Col>
					<Col xs={6} sm={6} md={6} lg={6} xl={6}>
						<Button
							type='button'
							buttonAlias='Primary'
							buttonText='Add Contribution'
							onClick={() => {
								setAddModal(true)
							}}
							className={styles.button}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						{isEmpty(contributions) ? (
							<Font
								fontAlias='P1'
								fontText='No current contributions'
							/>
						) : (
							<ContributionTable
								data={contributions}
								selectContribution={(contribution) => {
									setEditModal(true)
									setSelectedContribution(contribution)
								}}
							/>
						)}
					</Col>
				</Row>
				{!isEmpty(contributions) && (
					<Row>
						<Col sm={12}>
							<Pagination
								next={query.next}
								previous={query.previous}
								onClick={paginatorClick}
								totalCount={query.count}
								perPageCount={10}
								activePage={query.currentPage}
							/>
						</Col>
					</Row>
				)}
				<br />
				<br />
				<br />
			</Container>
			<EditModal
				visible={editModal}
				selectedContribution={selectedContribution}
				editSubmitted={editSubmitted}
				editClose={editClose}
			/>
			<AddModal
				visible={addModal}
				addClose={addClose}
				addSubmitted={addSubmitted}
				hasError={hasError}
			/>
		</>
	)
}

Contributions.propTypes = {}

export default Contributions
