import React from 'react'
import { Row, Col, Hidden, Visible } from 'react-grid-system'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Font from '../Font'
// import BinImage from '../../images/bin.svg'
import EyeOpen from '../../images/eye-open.svg'
import PlaceholderAvatar from '../../images/placeholder.png'
import EyeClose from '../../images/eye-close.svg'
import styles from './index.module.css'

const Comment = ({
	image,
	name,
	content,
	reportText,
	status,
	commentId,
	changeCommentStatus,
	userId,
}) => {
	const hideComment = () => {
		changeCommentStatus(commentId, 'hide')
	}

	const unHideComment = () => {
		changeCommentStatus(commentId, 'unhide')
	}
	const setOnErrorImage = (e) => {
		e.currentTarget.src = PlaceholderAvatar
	}

	return (
		<Row>
			<Col sm={12} lg={12} md={12}>
				<div className={styles.commentContainer}>
					<div className={styles.header}>
						<img
							src={image || PlaceholderAvatar}
							onError={setOnErrorImage}
							alt=''
						/>
						<Link
							className={styles.textDecorationNone}
							to={`/users/${userId}`}
						>
							<Font fontAlias='H3' fontText={name} />
						</Link>
						<Hidden sm xs>
							<div className={styles.options}>
								{/* <button type='button'>
									<img src={BinImage} alt='Bin' />
									<Font fontAlias='P1' fontText='DELETE' />
								</button> */}
								<button
									type='button'
									className={styles.reportButton}
								>
									{reportText}
								</button>

								{status ? (
									<button type='button' onClick={hideComment}>
										<img src={EyeOpen} alt='Bin' />
										<Font fontAlias='P1' fontText='Hide' />
									</button>
								) : (
									<button
										type='button'
										onClick={unHideComment}
									>
										<img src={EyeClose} alt='Bin' />
										<Font
											fontAlias='P1'
											fontText='Unhide'
										/>
									</button>
								)}
							</div>
						</Hidden>
					</div>
					<br />
					<div className={styles.content}>
						<Font fontAlias='P1' fontText={content} />
					</div>
					{/* <Hidden sm xs>
						<br />
						<button type='button' className={styles.reportButton}>
							{reportText}
						</button>
						<br />
					</Hidden> */}

					<Visible sm xs>
						<div>
							<br />
							<button
								type='button'
								className={styles.reportButton}
							>
								{reportText}
							</button>
							<div
								className={`${styles.options} ${styles.hideBtn}`}
							>
								{/* <button type='button'>
									<img src={BinImage} alt='Bin' />
									<Font fontAlias='P1' fontText='DELETE' />
								</button> */}
								{status ? (
									<button type='button' onClick={hideComment}>
										<img src={EyeOpen} alt='Bin' />
										<Font fontAlias='P1' fontText='Hide' />
									</button>
								) : (
									<button
										type='button'
										onClick={unHideComment}
									>
										<img src={EyeClose} alt='Bin' />
										<Font
											fontAlias='P1'
											fontText='Unhide'
										/>
									</button>
								)}
							</div>
						</div>
					</Visible>
					<br />
				</div>
			</Col>
		</Row>
	)
}

Comment.propTypes = {
	image: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
	reportText: PropTypes.string.isRequired,
	status: PropTypes.bool.isRequired,
	commentId: PropTypes.string.isRequired,
	userId: PropTypes.string.isRequired,
	changeCommentStatus: PropTypes.func.isRequired,
}

export default Comment
