import { ReportActions } from './constants'

export const setAppState = (payload) => ({
	type: ReportActions.setAppState,
	payload,
})

export const setQuery = (payload) => ({
	type: ReportActions.setQuery,
	payload,
})

//

export const requestStories = () => ({
	type: ReportActions.requestStories,
})

export const setStories = (payload) => ({
	type: ReportActions.setStories,
	payload,
})

export const requestStory = (payload) => ({
	type: ReportActions.requestStory,
	payload,
})

export const setStory = (payload) => ({
	type: ReportActions.setStory,
	payload,
})

export const requestStoryComments = (payload) => ({
	type: ReportActions.requestStoryComments,
	payload,
})

export const setStoryCommentsQuery = (payload) => ({
	type: ReportActions.setStoryCommentsQuery,
	payload,
})

export const setStoryCommentsList = (payload) => ({
	type: ReportActions.setStoryCommentsList,
	payload,
})

export const requestHideStory = (payload) => ({
	type: ReportActions.requestHideStory,
	payload,
})

export const setHideStory = (payload) => ({
	type: ReportActions.setHideStory,
	payload,
})

export const requestMarkStorySafe = (payload) => ({
	type: ReportActions.requestMarkStorySafe,
	payload,
})

export const setMarkStorySafe = () => ({
	type: ReportActions.setMarkStorySafe,
})

export const resetStoryDetail = () => ({
	type: ReportActions.resetStoryDetail,
})

//

export const requestUsers = () => ({
	type: ReportActions.requestUsers,
})

export const setUsers = (payload) => ({
	type: ReportActions.setUsers,
	payload,
})

export const requestUser = (payload) => ({
	type: ReportActions.requestUser,
	payload,
})

export const setUser = (payload) => ({
	type: ReportActions.setUser,
	payload,
})

export const requestHideUser = (payload) => ({
	type: ReportActions.requestHideUser,
	payload,
})

export const setHideUser = (payload) => ({
	type: ReportActions.setHideUser,
	payload,
})

//

export const requestComments = () => ({
	type: ReportActions.requestComments,
})

export const setComments = (payload) => ({
	type: ReportActions.setComments,
	payload,
})

export const requestChangeCommentState = (payload) => ({
	type: ReportActions.requestChangeCommentState,
	payload,
})

export const setCommentState = (payload) => ({
	type: ReportActions.setCommentState,
	payload,
})

//

export const requestUsersReported = (payload) => ({
	type: ReportActions.requestUsersReported,
	payload,
})

export const setUsersReported = (payload) => ({
	type: ReportActions.setUsersReported,
	payload,
})

//
export const requestHideUnhideStoryComments = (payload) => ({
	type: ReportActions.requestHideUnhideStoryComments,
	payload,
})

export const setHideUnhideStoryComments = (payload) => ({
	type: ReportActions.settHideUnhideStoryComments,
	payload,
})
