import React from 'react'
import { Col } from 'react-grid-system'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import PlaceholderAvatar from '../../../../images/placeholder.png'
import styles from './VerificationList.module.css'

const VerificationList = ({ data }) => {
	return data.map((story) => {
		return (
			<Col
				sm={12}
				md={6}
				lg={5}
				className={styles.verificationContainer}
				key={`verify-story-${story.id}`}
			>
				<div className={`${styles.verificationImage} ${styles.imgUp}`}>
					<img
						src={story.image}
						className={styles.VerificationIMG}
						alt={`Verification ${story.id}`}
					/>
				</div>

				<div className={styles.verification}>
					<div className={styles.verificationTop}>
						<img
							src={story.user.avatar || PlaceholderAvatar}
							alt={`Avatar ${story.user.username}`}
						/>
						<div>
							<Link
								className={styles.textDecorationNone}
								to={`/users/${story.user.id}`}
							>
								<h2>{story.user.username}</h2>
							</Link>
							<p className={styles.locationText}>
								{story.user.lat ? (
									<a
										href={`https://www.google.com/maps/search/?api=1&query=${story.user.lat},${story.user.lng}`}
										target='_blank'
										rel='noopener noreferrer'
									>
										{story.user.lat}, {story.user.lng}
									</a>
								) : (
									<a
										href={`https://www.google.com/maps/search/?api=1&query=${story.user.lat},${story.user.lng}`}
										target='_blank'
										rel='noopener noreferrer'
									/>
								)}
							</p>
						</div>
					</div>
					<p className={styles.verificationPara}>{story.content}</p>
				</div>

				<div
					className={`${styles.verificationImage} ${styles.imgDown}`}
				>
					<img src={story.image} alt={`Verification ${story.id}`} />
				</div>
			</Col>
		)
	})
}

VerificationList.propTypes = {
	data: PropTypes.array,
}

export default VerificationList
