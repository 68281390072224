import React, { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Container, Row, Col, Hidden, Visible } from 'react-grid-system'
import PlaceholderAvatar from '../../../images/placeholder.png'

import { AppState } from '../../../store/constants'
import BackButton from '../../../images/back-button.svg'
import { Font, Spinner, Pagination, Story, Select } from '../../../components'

import {
	requestUser,
	requestHideUser,
	setQuery,
	requestStories,
} from '../actions'
import {
	selectUser,
	selectStories,
	selectAppState,
	selectQuery,
	// selectPicture,
} from '../selector'

import styles from './index.module.css'

const User = () => {
	const dispatch = useDispatch()
	const params = useParams()
	const history = useHistory()

	const appState = useSelector(selectAppState)
	const user = useSelector(selectUser)
	const query = useSelector(selectQuery)
	const stories = useSelector(selectStories)
	// const picture = useSelector(selectPicture)

	useEffect(() => {
		dispatch(requestUser(params.id))
		// dispatch(requestStories('03023875446'))
		return () => {
			dispatch(
				setQuery({
					next: null,
					previous: null,
					currentPage: 1,
					count: 0,
				}),
			)
		}
	}, [dispatch, params.id])

	useEffect(() => {
		dispatch(requestStories(user.mobile))
	}, [user.mobile])

	if (appState === AppState.IDLE || appState === AppState.LOADING) {
		return <Spinner />
	}

	const paginatorClick = (type) => {
		if (type === 'NEXT') {
			dispatch(
				setQuery({
					currentPage: query.currentPage + 1,
				}),
			)
		} else if (type === 'PREV') {
			dispatch(
				setQuery({
					currentPage: query.currentPage - 1,
				}),
			)
		} else {
			dispatch(
				setQuery({
					currentPage: type,
				}),
			)
		}
		dispatch(requestStories(user.mobile))
	}

	const clickedItem = (item) => {
		const isActive = item.value === 'enabled'
		dispatch(
			requestHideUser({
				id: params.id,
				isActive,
			}),
		)
	}

	const setOnErrorImage = (e) => {
		e.currentTarget.src = PlaceholderAvatar
	}

	const renderUserDetail = () => {
		return (
			<>
				<div style={{ marginTop: '-0px' }}>
					<img
						src={user.picture || PlaceholderAvatar}
						alt='User avatar'
						className={styles.profilePicture}
						onError={setOnErrorImage}
					/>
					<Col
						xs={12}
						sm={12}
						md={6}
						lg={8}
						xl={8}
						className={styles.profileDetail}
					>
						<div className={styles.marginTop20}>
							<span className={styles.heading}>{user.name}</span>
							<Hidden md sm xs>
								<div className={styles.optionsSec}>
									<Select
										items={[
											{
												name: user.isActive
													? 'Block'
													: 'Enabled',
												value: user.isActive
													? 'block'
													: 'enabled',
												canOpenModal: true,
												modalText: user.isActive
													? 'Are you sure you want to block this user'
													: 'Are you sure you  want to enable this user',
											},
										]}
										itemClicked={clickedItem}
									/>
								</div>
							</Hidden>
						</div>

						<p className={styles.para}>{user.email}</p>
						<p className={styles.para}>{user.mobile}</p>
						<p className={`${styles.para} ${styles.paraStrong}`}>
							<strong>{user.totalPosts}</strong> Posts
						</p>
					</Col>
				</div>
			</>
		)
	}

	const renderStories = () => {
		if (isEmpty(stories)) {
			return <Col sm={12} />
		}

		return stories.map((story) => {
			return (
				<Col sm={6} md={6} lg={4} key={`story-${story.id}`}>
					<Story
						count={`${story.reportCount} people`}
						title={story.title}
						date={story.date}
						anonymous={story.anonymous}
						storyId={story.id}
						userId={story.user.id}
						username={story.user.name}
						userAvatar={story.user.avatar}
						storyImage={story.image}
						isViewed={story.isViewed}
						isEdited={story.isEdited}
						link={`/stories/${story.id}`}
					/>
				</Col>
			)
		})
	}

	return (
		<Container>
			{/* <img
				alt='Users'
				src={picture || PlaceholderAvatar}
				className={styles.avatar}
			/> */}
			<Row className={styles.topSection}>
				<Col xs={6} sm={6} md={6} lg={6}>
					<button
						onClick={() => {
							history.goBack()
						}}
						type='button'
						className={styles.linkContainer}
					>
						<img
							src={BackButton}
							className={styles.backSvg}
							alt='Back button'
						/>
						<Font fontAlias='P1' fontText='Back' />
					</button>
				</Col>
				{!isEmpty(user) && (
					<Col xs={6} sm={6} md={6} lg={6}>
						{/* {user.isActive && (
							<Button
								buttonText='Hide User'
								type='button'
								onClick={hideUser}
								className={styles.button}
							/>
						)} */}
						<Visible md sm xs>
							<div className={styles.options}>
								<Select
									items={[
										{
											name: user.isActive
												? 'Block'
												: 'Enabled',
											value: user.isActive
												? 'block'
												: 'enabled',
											canOpenModal: true,
											modalText: user.isActive
												? 'Are you sure you want to block this user'
												: 'Are you sure you  want to enable this user',
										},
									]}
									itemClicked={clickedItem}
								/>
							</div>
						</Visible>
					</Col>
				)}
			</Row>
			{isEmpty(user) ? (
				<Row>
					<Col sm={12} md={12} lg={12} xs={12} xl={12}>
						<Font
							fontAlias='H3'
							fontText={`User with id ${params.id} does not exist`}
						/>
					</Col>
				</Row>
			) : (
				<>
					{renderUserDetail()}
					<hr className={styles.dividerLine} />
					<br />
					<Row>{renderStories()}</Row>
					<br />
					{!isEmpty(stories) && (
						<Row>
							<Col sm={12}>
								<Pagination
									next={query.next}
									previous={query.previous}
									onClick={paginatorClick}
									totalCount={query.count}
									perPageCount={12}
									activePage={query.currentPage}
								/>
							</Col>
						</Row>
					)}
					<br />
					<br />
				</>
			)}
		</Container>
	)
}

export default User
