import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import { useHistory } from 'react-router-dom'
import { isEmpty, debounce } from 'lodash'

import { AppState } from '../../../store/constants'
import { Comment, Spinner, Pagination, Search } from '../../../components'
import { useQuery } from '../../../utils'

import { selectComments, selectQuery, selectAppState } from '../selector'
import {
	requestComments,
	setQuery,
	requestChangeCommentState,
} from '../actions'
import Header from '../helpers/Header'

const Comments = () => {
	const dispatch = useDispatch()
	const appState = useSelector(selectAppState)
	const query = useSelector(selectQuery)
	const comments = useSelector(selectComments)
	const pageQuery = useQuery()
	const history = useHistory()

	useEffect(() => {
		const currentPage = Number(pageQuery.get('page')) || 1
		const searchTerm = pageQuery.get('content') || ''
		dispatch(
			setQuery({
				...query,
				currentPage,
				searchTerm,
			}),
		)
		dispatch(requestComments())

		return () => {
			dispatch(
				setQuery({
					next: null,
					previous: null,
					currentPage: 1,
					count: 0,
					searchTerm: '',
				}),
			)
		}
	}, [dispatch])

	useEffect(() => {
		let buildQuery = `?page=${query.currentPage || 1}`

		buildQuery = query.searchTerm
			? `${buildQuery}&content=${query.searchTerm}`
			: buildQuery

		history.replace(`/reports/comments${buildQuery}`)
	}, [query])

	if (appState === AppState.IDLE || appState === AppState.LOADING) {
		return <Spinner />
	}

	const paginatorClick = (type) => {
		if (type === 'NEXT') {
			dispatch(
				setQuery({
					currentPage: query.currentPage + 1,
				}),
			)
		} else if (type === 'PREV') {
			dispatch(
				setQuery({
					currentPage: query.currentPage - 1,
				}),
			)
		} else {
			dispatch(
				setQuery({
					currentPage: type,
				}),
			)
		}
		dispatch(requestComments())
	}

	const changeCommentStatus = (commentId, type) => {
		dispatch(
			requestChangeCommentState({
				id: commentId,
				type,
			}),
		)
	}

	const doSearch = debounce((term) => {
		dispatch(
			setQuery({
				...query,
				searchTerm: term,
			}),
		)
		dispatch(requestComments())
	}, 500)

	const renderComments = () => {
		if (isEmpty(comments)) {
			return (
				<Row>
					<Col sm={12}>
						<h2>Alertli currently does not have any comments</h2>
					</Col>
				</Row>
			)
		}

		return comments.map((comment) => {
			return (
				<Comment
					userId={comment.user.id}
					content={comment.content}
					image={comment.user.picture}
					key={`comment-${comment.id}`}
					name={comment.user.name}
					reportText={`Reported: ${comment.reportCount} Times`}
					status={comment.commentStatus}
					commentId={comment.commentId}
					changeCommentStatus={changeCommentStatus}
				/>
			)
		})
	}

	return (
		<>
			<Search value={query.searchTerm} doSearch={doSearch} />
			<Container>
				<Row>
					<Col sm={12} md={12} lg={12}>
						<Header />
					</Col>
				</Row>
				<br />
				{renderComments()}
				{!isEmpty(comments) && (
					<>
						<br />
						<br />
						<Row>
							<Col sm={12}>
								<Pagination
									next={query.next}
									previous={query.previous}
									onClick={paginatorClick}
									totalCount={query.count}
									perPageCount={12}
									activePage={query.currentPage}
								/>
							</Col>
						</Row>
						<br />
						<br />
					</>
				)}
			</Container>
		</>
	)
}

export default Comments
