import React from 'react'
import { useParams } from 'react-router-dom'

import Login from './Login'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import Success from './Success'

export default () => {
	const { path } = useParams()
	switch (path) {
		case 'login': {
			return <Login />
		}
		case 'forgotpassword': {
			return <ForgotPassword />
		}
		case 'resetpassword': {
			return <ResetPassword />
		}
		case 'success': {
			return <Success />
		}
		default: {
			return <Login />
		}
	}
}
