import { ContributionsActions } from './constants'

export const setAppState = (payload) => ({
	type: ContributionsActions.setAppState,
	payload,
})

export const requestContributions = () => ({
	type: ContributionsActions.requestContributions,
})

export const setContributions = (payload) => ({
	type: ContributionsActions.setContributions,
	payload,
})

export const setCurrentPage = (payload) => ({
	type: ContributionsActions.setCurrentPage,
	payload,
})

export const requestChangeContributionStatus = (payload) => ({
	type: ContributionsActions.requestChangeContributionStatus,
	payload,
})

export const requestChangeContribution = (payload) => ({
	type: ContributionsActions.requestChangeContribution,
	payload,
})

export const setChangeContribution = (payload) => ({
	type: ContributionsActions.setChangeContribution,
	payload,
})

export const requestAddChallenge = (payload, cb) => ({
	type: ContributionsActions.requestAddChallenge,
	payload,
	cb,
})

export const setError = () => ({ type: ContributionsActions.setError })
export const clearError = () => ({ type: ContributionsActions.clearError })
