import { AppState } from '../../store/constants'
import { StoriesActions } from './constants'

const initialState = {
	appState: AppState.IDLE,
	stories: [],
	count: 0,
	next: null,
	previous: null,

	tags: [],

	query: {
		currentPage: 1,
		filterTag: '',
		startDate: '',
		endDate: '',
		title: '',
	},

	detail: {
		story: {},
		comments: [],
		commentPage: 1,
		commentLoading: AppState.LOADING,
		canGoNext: true,
		commentCount: 0,
	},

	error: '',
}

export default (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case StoriesActions.setLoading: {
			return {
				...state,
				appState: payload,
			}
		}
		case StoriesActions.setStories: {
			return {
				...state,
				stories: payload.stories,
				count: payload.count,
				next: payload.next,
				previous: payload.previous,
			}
		}
		case StoriesActions.setTags: {
			return {
				...state,
				tags: payload,
			}
		}
		case StoriesActions.setBuildQuery: {
			return {
				...state,
				query: {
					...payload,
				},
			}
		}
		case StoriesActions.setStory: {
			return {
				...state,
				detail: {
					...state.detail,
					story: {
						...payload,
					},
				},
			}
		}
		case StoriesActions.setHideStory: {
			return {
				...state,
				detail: {
					...state.detail,
					story: {
						...state.detail.story,
						status: 'Inactive',
					},
				},
			}
		}
		case StoriesActions.setStoryMarkSafe: {
			return {
				...state,
				detail: {
					...state.detail,
					story: {
						...state.detail.story,
						story_admin_action: [{ action: 'Mark As Safe' }],
					},
				},
			}
		}
		case StoriesActions.setUnHideStory: {
			return {
				...state,
				detail: {
					...state.detail,
					story: {
						...state.detail.story,
						status: 'Publish',
					},
				},
			}
		}
		case StoriesActions.setStoryCommentsQuery: {
			return {
				...state,
				detail: {
					...state.detail,
					commentPage: payload.commentPage,
					commentLoading: payload.commentLoading,
					canGoNext: payload.canGoNext,
					commentCount: payload.commentCount,
				},
			}
		}
		case StoriesActions.setStoryCommentsList: {
			return {
				...state,
				detail: {
					...state.detail,
					comments: [...state.detail.comments, ...payload],
				},
			}
		}
		case StoriesActions.setHideUnhideStoryComments: {
			const comments = state.detail.comments.map((comment) => {
				if (comment.id === payload.id) {
					return {
						...comment,
						status: payload.status,
					}
				}
				return comment
			})
			return {
				...state,
				detail: {
					...state.detail,
					comments,
				},
			}
		}
		case StoriesActions.resetStoryDetail: {
			return {
				...state,
				detail: {
					story: {},
					comments: [],
					commentPage: 1,
					commentLoading: AppState.LOADING,
					canGoNext: true,
					commentCount: 0,
				},
			}
		}
		default: {
			return state
		}
	}
}
