import React from 'react'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import PropTypes from 'prop-types'

import rootReducer from './reducers'
import sagas from './sagas'

// Saga middleware will come here

const sagaMiddleware = createSagaMiddleware()

const middleware = [sagaMiddleware]

const store = createStore(
	rootReducer,
	composeWithDevTools(applyMiddleware(...middleware)),
	// applyMiddleware(sagaMiddleware),
)

sagaMiddleware.run(sagas)

const Store = ({ children }) => {
	return <Provider store={store}>{children}</Provider>
}

Store.propTypes = {
	children: PropTypes.node.isRequired,
}

export { store }

export default Store
