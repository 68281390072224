/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import EyeOpen from '../../images/eye-open.svg'
import EyeClose from '../../images/eye-close.svg'
import styles from './index.module.css'

export default function Input({
	inputGroupStyle,
	inputStyle,
	name,
	htmlFor,
	inputRef,
	errorText,
	type,
	label,
	labelStyle,
	placeholder,
	isPassword,
}) {
	const labelStyleClass = classnames(styles.label, labelStyle)
	const inputGroup = classnames(styles.inputGroup, inputGroupStyle)
	const inputStyleClass = classnames(styles.formControl, inputStyle)

	const [passHidden, setPassHidden] = useState(false)

	return (
		<div className={inputGroup}>
			{label ? (
				<label htmlFor={htmlFor} className={labelStyleClass}>
					{label}
				</label>
			) : (
				<div />
			)}
			{isPassword && (
				<>
					{passHidden ? (
						<img
							src={EyeOpen}
							className={styles.eyeIcon}
							alt='Eye'
							onClick={() => {
								setPassHidden((n) => !n)
							}}
						/>
					) : (
						<img
							src={EyeClose}
							className={styles.eyeIcon}
							alt='Eye'
							onClick={() => {
								setPassHidden((n) => !n)
							}}
						/>
					)}
				</>
			)}
			<input
				id={htmlFor}
				ref={inputRef}
				type={passHidden ? 'text' : type}
				name={name}
				placeholder={placeholder}
				className={inputStyleClass}
			/>
			{errorText && <span className={styles.errorText}>{errorText}</span>}
		</div>
	)
}

Input.propTypes = {
	inputGroupStyle: PropTypes.string,
	inputStyle: PropTypes.string,
	errorText: PropTypes.string,
	isPassword: PropTypes.bool,

	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	htmlFor: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	inputRef: PropTypes.any.isRequired,
	labelStyle: PropTypes.any.isRequired,
	placeholder: PropTypes.isRequired,
}

Input.defaultProps = {
	inputGroupStyle: '',
	inputStyle: '',
	errorText: '',
	isPassword: false,
}
