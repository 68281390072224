/* eslint-disable import/prefer-default-export */
const domain = 'auth'

export const StoriesActions = {
	requestStories: `${domain}/requestStories`,
	setStories: `${domain}/setStories`,

	requestTags: `${domain}/requestTags`,
	setTags: `${domain}/setTags`,

	setLoading: `${domain}/setLoading`,

	setBuildQuery: `${domain}/setBuildQuery`,

	requestGetStory: `${domain}/requestGetStory`,
	setStory: `${domain}/setStory`,

	requestStoryComments: `${domain}/requestStoryComments`,
	setStoryCommentsList: `${domain}/setStoryCommentsList`,
	setStoryCommentsQuery: `${domain}/setStoryCommentsQuery`,

	requestHideUnhideStoryComments: `${domain}/requestHideUnhideStoryComments`,
	setHideUnhideStoryComments: `${domain}/setHideUnhideStoryComments`,

	requestHideStory: `${domain}/requestHideStory`,
	setHideStory: `${domain}/setHideStory`,

	requestUnHideStory: `${domain}/requestUnHideStory`,
	setUnHideStory: `${domain}/setUnHideStory`,

	requestStoryMarkSafe: `${domain}/requestStoryMarkSafe`,
	setStoryMarkSafe: `${domain}/setStoryMarkSafe`,

	resetStoryDetail: `${domain}/resetStoryDetail`,
}
