/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import { Select } from '../../../../components'

import PlaceholderAvatar from '../../../../images/placeholder.png'
import styles from './UserSection.module.css'

const UserSection = ({
	user,
	time,
	location,
	isAnon,
	active,
	markSafe,
	changeStoryStatus,
}) => {
	const clickedItem = (item) => {
		changeStoryStatus(item)
	}
	return (
		<div className={styles.userSection}>
			<img
				src={user.picture || PlaceholderAvatar}
				alt=''
				className={styles.userAvatar}
			/>
			<div className={styles.userDetail}>
				<div className={styles.userHeadingContainer}>
					{isAnon ? (
						<Link
							className={styles.textDecorationNone}
							to={`/users/${user.id}`}
						>
							<h1 className={styles.userHeading}>
								Anonymous User ( {user.name} )
							</h1>
						</Link>
					) : (
						<Link
							className={styles.textDecorationNone}
							to={`/users/${user.id}`}
						>
							<h1 className={styles.userHeading}>{user.name}</h1>
						</Link>
					)}
					<span>{time}</span>
				</div>
				<p className={styles.locationText}>
					{/* {location} , {coords} */}
					<a
						href={`https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lng}`}
						target='_blank'
						rel='noopener noreferrer'
					>
						{location.address}
					</a>
					{user.lat ? (
						<a
							href={`https://www.google.com/maps/search/?api=1&query=${user.lat},${user.lng}`}
							target='_blank'
							rel='noopener noreferrer'
						>
							{' '}
							, {user.lat} , {user.lng}
						</a>
					) : (
						<a
							role='button'
							href={`https://www.google.com/maps/search/?api=1&query=${user.lat},${user.lng}`}
							target='_blank'
							rel='noopener noreferrer'
						/>
					)}
				</p>
			</div>
			{!markSafe && active ? (
				<>
					<Select
						items={[
							{
								name: active ? 'Hide' : 'Story is Hidden',
								value: active ? 'hide' : '',
								canOpenModal: active,
								modalText:
									'Are you sure you want to hide the story',
							},
							{
								name: markSafe ? 'Marked as Safe' : 'Mark Safe',
								value: markSafe ? '' : 'markSafe',
								canOpenModal: !markSafe,
								modalText:
									'Are you sure you want to mark the story as safe',
							},
						]}
						itemClicked={clickedItem}
					/>
				</>
			) : (
				<>
					{markSafe ? (
						<>
							<Select
								items={[
									{
										name: markSafe
											? 'Marked as Safe'
											: 'Mark Safe',
										value: markSafe ? '' : 'markSafe',
										canOpenModal: !markSafe,
										modalText:
											'Are you sure you want to mark the story as safe',
									},
								]}
								itemClicked={clickedItem}
							/>
						</>
					) : (
						<>
							<Select
								items={[
									{
										name: active
											? 'Hide'
											: 'Story is Hidden',
										value: active ? 'hide' : '',
										canOpenModal: active,
										modalText:
											'Are you sure you want to hide the story',
									},
								]}
								itemClicked={clickedItem}
							/>
						</>
					)}
				</>
			)}
		</div>
	)
}

UserSection.propTypes = {
	user: PropTypes.object.isRequired,
	time: PropTypes.string.isRequired,
	location: PropTypes.object.isRequired,
	isAnon: PropTypes.bool.isRequired,
	active: PropTypes.bool.isRequired,
	markSafe: PropTypes.bool.isRequired,

	changeStoryStatus: PropTypes.func.isRequired,
}

export default UserSection
