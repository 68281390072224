import { createSelector } from 'reselect'

export const selectAppState = createSelector(
	(state) => state.auth,
	(auth) => auth.appState,
)

export const selectError = createSelector(
	(state) => state.auth,
	(auth) => auth.error,
)
