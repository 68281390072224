import React from 'react'
import { Row, Col, Hidden } from 'react-grid-system'
import { Link } from 'react-router-dom'

import { BackgroundImage, Font } from '../../../components'
import AlertliHeader from '../helper/Logo'

import styles from '../index.module.css'

function Success() {
	return (
		<Row>
			<Col md={6} lg={7}>
				<Hidden xs sm>
					<BackgroundImage />
				</Hidden>
			</Col>
			<Col md={6} lg={5}>
				<div className={styles.form}>
					<AlertliHeader />
					<Font fontText='Password Changed' fontAlias='H1' />
					<br />
					<div className={styles.mainPara}>
						<Font
							fontAlias='P1'
							fontText='Your password is successfully changed.'
						/>
						<Link to='/' className={styles.textDecorationNone}>
							<Font fontAlias='P2' fontText='Back To Sign In.' />
						</Link>
					</div>
				</div>
			</Col>
		</Row>
	)
}

export default Success
