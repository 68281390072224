/* eslint-disable no-console */
import { all, call, takeLatest, put, select } from 'redux-saga/effects'

import { AppState } from '../../store/constants'
import { ReportActions } from './constants'
import {
	setAppState,
	setStories,
	setQuery,
	setUsers,
	setUser,
	setComments,
	setStory,
	setStoryCommentsList,
	setStoryCommentsQuery,
	setMarkStorySafe,
	setHideStory,
	setHideUser,
	setCommentState,
	setUsersReported,
	setHideUnhideStoryComments,
} from './actions'
import { selectQuery, selectStoryCommentQuery } from './selector'
import {
	apiGetReportedStories,
	apiGetReportedUsers,
	apiStoryMarkSafe,
	apiGetReportedComments,
	apiGetComments,
	apiHideUnhideComment,
	apiGetReportedUser,
	apiHideStory,
	apiGetReportedBy,
	apiHideUser,
	apiGetStory,
} from '../../store/api'
import { routerPush } from '../../utils'

function* requestStories() {
	try {
		yield put(setAppState(AppState.LOADING))
		const query = yield select(selectQuery)

		let buildQuery = `?page=${query.currentPage || 1}`

		buildQuery = query.searchTerm
			? `${buildQuery}&search=${query.searchTerm}`
			: buildQuery

		const res = yield call(apiGetReportedStories, buildQuery)
		const { data } = res
		yield put(setStories(data.results.data))
		yield put(
			setQuery({
				count: data.count,
				next: data.next,
				previous: data.previous,
			}),
		)
	} catch (err) {
		console.log('requestStories', err)
	} finally {
		yield put(setAppState(AppState.READY))
	}
}

function* requestStory({ payload }) {
	try {
		yield put(setAppState(AppState.LOADING))

		const res = yield call(apiGetStory, payload)
		yield put(setStory(res.data.data))
	} catch (err) {
		console.log('requestUser: ', err)
	} finally {
		yield put(setAppState(AppState.READY))
	}
}

function* requestHideStory({ payload }) {
	try {
		yield call(apiHideStory, payload)
		yield put(setHideStory())
	} catch (err) {
		console.log('Hide story', err)
	}
}

function* requestMarkStorySafe({ payload }) {
	try {
		yield call(apiStoryMarkSafe, payload, 'Mark As Safe')
		yield put(setMarkStorySafe())
	} catch (err) {
		console.log('requestStoryMarkSafe', err)
	}
}

function* requestStoryComments({ payload }) {
	try {
		const commentQuery = yield select(selectStoryCommentQuery)
		if (commentQuery.canGoNext) {
			const res = yield call(
				apiGetComments,
				payload,
				commentQuery.commentPage,
			)
			const { data } = res

			const nextPage = commentQuery.commentPage + 1
			yield put(
				setStoryCommentsQuery({
					canGoNext: data.next,
					commentLoading: AppState.READY,
					commentPage: nextPage,
					commentCount: data.count,
				}),
			)
			yield put(setStoryCommentsList(data.results.data))
		}
	} catch (err) {
		console.log('Story comments')
	}
}

function* requestUsers() {
	try {
		yield put(setAppState(AppState.LOADING))
		const query = yield select(selectQuery)

		let buildQuery = `?page=${query.currentPage || 1}`

		buildQuery = query.searchTerm
			? `${buildQuery}&search=${query.searchTerm}`
			: buildQuery

		const res = yield call(apiGetReportedUsers, buildQuery)
		const { data } = res
		yield put(setUsers(data.results.data))
		yield put(
			setQuery({
				count: data.count,
				next: data.next,
				previous: data.previous,
			}),
		)
	} catch (err) {
		console.log('requestStories', err)
	} finally {
		yield put(setAppState(AppState.READY))
	}
}

function* requestComments() {
	try {
		yield put(setAppState(AppState.LOADING))
		const query = yield select(selectQuery)

		let buildQuery = `?page=${query.currentPage || 1}`

		buildQuery = query.searchTerm
			? `${buildQuery}&search=${query.searchTerm}`
			: buildQuery

		const res = yield call(apiGetReportedComments, buildQuery)
		const { data } = res
		yield put(setComments(data.results.data))
		yield put(
			setQuery({
				count: data.count,
				next: data.next,
				previous: data.previous,
			}),
		)
	} catch (err) {
		console.log('requestStories', err)
	} finally {
		yield put(setAppState(AppState.READY))
	}
}

function* requestChangeCommentState({ payload }) {
	try {
		if (payload.type === 'hide') {
			yield call(apiHideUnhideComment, payload.id, false)
			yield put(setCommentState({ id: payload.id, state: false }))
		} else {
			yield call(apiHideUnhideComment, payload.id, true)
			yield put(setCommentState({ id: payload.id, state: true }))
		}
	} catch (err) {
		console.log('requestChangeCommentState', err)
	}
}

function* requestUser({ payload }) {
	try {
		yield put(setAppState(AppState.LOADING))

		const user = yield call(apiGetReportedUser, payload)

		yield put(setUser(user.data))
	} catch (err) {
		console.log('requestUser: ', err)
	} finally {
		yield put(setAppState(AppState.READY))
	}
}

function* requestHideUser({ payload }) {
	try {
		yield call(apiHideUser, payload.id, payload.isActive)
		yield put(
			setHideUser({
				id: payload.id,
				is_active: payload.isActive,
			}),
		)
		routerPush('/reports/users')
	} catch (err) {
		console.log('requestHideUser: ', err)
	}
}

function* requestUsersReported({ payload }) {
	try {
		const res = yield call(apiGetReportedBy, payload.id, payload.type)

		yield put(setUsersReported(res.data.results.data))
		yield put(
			setQuery({
				count: res.data.count,
				next: res.data.next,
				previous: res.data.previous,
			}),
		)
	} catch (err) {
		console.log('requestUsersReported: ', err)
	} finally {
		yield put(setAppState(AppState.READY))
	}
}

function* requestHideUnhideStoryComments({ payload }) {
	try {
		if (payload.type === 'hide') {
			yield call(apiHideUnhideComment, payload.id, false)
			yield put(
				setHideUnhideStoryComments({ id: payload.id, status: false }),
			)
		} else {
			yield call(apiHideUnhideComment, payload.id, true)
			yield put(
				setHideUnhideStoryComments({ id: payload.id, status: true }),
			)
		}
	} catch (err) {
		console.log('requestHideUnhideStoryComments: ', err)
	}
}

export default function* () {
	yield all([
		yield takeLatest(ReportActions.requestStories, requestStories),
		yield takeLatest(ReportActions.requestUsers, requestUsers),
		yield takeLatest(ReportActions.requestComments, requestComments),
		yield takeLatest(
			ReportActions.requestChangeCommentState,
			requestChangeCommentState,
		),
		yield takeLatest(ReportActions.requestUser, requestUser),
		yield takeLatest(ReportActions.requestHideUser, requestHideUser),
		yield takeLatest(ReportActions.requestStory, requestStory),
		yield takeLatest(ReportActions.requestHideStory, requestHideStory),
		yield takeLatest(
			ReportActions.requestMarkStorySafe,
			requestMarkStorySafe,
		),
		yield takeLatest(
			ReportActions.requestUsersReported,
			requestUsersReported,
		),
		yield takeLatest(
			ReportActions.requestStoryComments,
			requestStoryComments,
		),
		yield takeLatest(
			ReportActions.requestHideUnhideStoryComments,
			requestHideUnhideStoryComments,
		),
	])
}
