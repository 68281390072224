/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import { Row, Col } from 'react-grid-system'
import { Link, useHistory } from 'react-router-dom'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import styles from './index.module.css'

import storiesImg from '../../images/icons/stories.png'
import userImg from '../../images/icons/user.png'
import reportImg from '../../images/icons/document.png'
import alertliLogo from '../../images/alertlilogo.svg'

function SidebarComponent({ isSuperUser }) {
	const {
		location: { pathname },
	} = useHistory()

	return (
		<Row>
			<Col sm={12}>
				<div className={styles.logoContainer}>
					<Link to='/'>
						<img alt='alertli' src={alertliLogo} />
					</Link>
				</div>
				<ul className={styles.navLinks}>
					<li
						className={classnames({
							[styles.active]: pathname === '/stories',
						})}
						onClick={() => {
							window.location.href = '/stories?page=1'
						}}
					>
						<img
							src={storiesImg}
							alt='Stories'
							className={styles.iconImg}
						/>
						<Link
							to='#'
							onClick={() => {
								window.location.href = '/stories?page=1'
							}}
						>
							Stories
						</Link>
					</li>
					<li
						className={classnames({
							[styles.active]:
								pathname === '/reports/stories' ||
								pathname === '/reports/comments' ||
								pathname === '/reports/users',
						})}
						onClick={() => {
							window.location.href = '/reports/stories?page=1'
						}}
					>
						<img
							src={reportImg}
							alt='Reports'
							className={styles.iconImg}
						/>
						<Link to='#'>Reports</Link>
					</li>
					<li
						onClick={() => {
							window.location.href = '/users?page=1'
						}}
						className={classnames({
							[styles.active]: pathname === '/users',
						})}
					>
						<img
							src={userImg}
							alt='Users'
							className={styles.iconImg}
						/>
						<Link to='#'>Users</Link>
					</li>
					{isSuperUser && (
						<li
							onClick={() => {
								window.location.href = '/admins?page=1'
							}}
							className={classnames({
								[styles.active]: pathname === '/admins',
							})}
						>
							<img
								src={userImg}
								alt='Admins'
								className={styles.iconImg}
							/>
							<Link to='#'>Admins</Link>
						</li>
					)}
					{isSuperUser && (
						<li
							onClick={() => {
								window.location.href = '/contributions?page=1'
							}}
							className={classnames({
								[styles.active]: pathname === '/contributions',
							})}
						>
							<img
								src={userImg}
								alt='Contributions'
								className={styles.iconImg}
							/>
							<Link to='#'>Contributions</Link>
						</li>
					)}
				</ul>
			</Col>
		</Row>
	)
}

SidebarComponent.propTypes = {
	isSuperUser: PropTypes.bool.isRequired,
}

export default SidebarComponent
