import { all, call, takeLatest, put, select } from 'redux-saga/effects'
import { AppState } from '../../store/constants'

import { ContributionsActions } from './constants'
import {
	apiAddChallenge,
	apiGetContributions,
	apiUpdateContribution,
} from '../../store/api'
import {
	setAppState,
	setContributions,
	requestContributions as requestContributionsAction,
} from './actions'
import { selectQuery } from './selector'

function* requestContributions() {
	try {
		yield put(setAppState(AppState.LOADING))
		const query = yield select(selectQuery)

		let buildQuery = `?page=${query.currentPage || 1}`

		buildQuery = query.searchTerm
			? `${buildQuery}&search=${query.searchTerm}`
			: buildQuery

		const res = yield call(apiGetContributions, buildQuery)
		const { data } = res
		yield put(
			setContributions({
				list: data.results,
				count: data.count,
				next: data.next,
				previous: data.previous,
			}),
		)
	} catch (err) {
		console.log('Request Contributions', err)
	} finally {
		yield put(setAppState(AppState.READY))
	}
}

function* requestAddChallenge({ payload, cb }) {
	try {
		const fD = new FormData()
		fD.append('month', payload.month)
		fD.append('year', payload.year)
		fD.append('description', payload.text)
		fD.append('image', payload.picture)
		yield call(apiAddChallenge, fD)
		yield put(requestContributionsAction())
		yield call(cb)
	} catch (err) {
		alert(err.response.request.response)
	}
}

function* requestChangeContribution({ payload }) {
	try {
		const fD = new FormData()
		fD.append('month', payload.month)
		fD.append('year', payload.year)
		fD.append('description', payload.description)
		if (payload.picture !== '') {
			fD.append('image', payload.picture)
		}
		yield call(apiUpdateContribution, payload.id, fD)
		yield put(requestContributionsAction())
	} catch (err) {
		alert(err.response.request.response)
	}
}
export default function* () {
	yield all([
		yield takeLatest(
			ContributionsActions.requestAddChallenge,
			requestAddChallenge,
		),
		yield takeLatest(
			ContributionsActions.requestContributions,
			requestContributions,
		),
		yield takeLatest(
			ContributionsActions.requestChangeContribution,
			requestChangeContribution,
		),
	])
}
