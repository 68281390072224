import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'

import { Modal, Input, Font, Button } from '../../../../components'
import { uploadToS3 } from '../../../../utils'

import styles from '../index.module.css'

function AddModal({ visible, addClose, addSubmitted, hasError }) {
	const { register, handleSubmit, errors, watch } = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
	})

	const [s3Location, setS3Location] = useState('')
	const [loadingImage, setLoadingImage] = useState(false)

	const passwordMatchError =
		watch('password') !== watch('confirmPassword')
			? 'Password fields did not match'
			: ''

	const imageFile = (e) => {
		setLoadingImage(true)
		uploadToS3(e.target.files[0]).then((data) => {
			setS3Location(data.location)
			setLoadingImage(false)
		})
	}

	const onSubmit = (data) => {
		if (!passwordMatchError) {
			addSubmitted({
				...data,
				picture: s3Location,
			})
		}
	}

	const onHideModal = () => {
		addClose()
	}

	return (
		<Modal visible={visible} closeModal={addClose}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div>
					<Font
						fontText='Admin Detail'
						fontAlias='H2'
						className={styles.modalTitle}
					/>
					<button
						type='button'
						onClick={onHideModal}
						className={styles.modalCross}
					>
						X
					</button>
				</div>

				<br />
				<Input
					name='name'
					label='Name'
					htmlFor='name'
					type='text'
					inputRef={register({
						required: 'Name field is required',
					})}
					errorText={errors.name && errors.name.message}
				/>
				<Input
					name='email'
					label='Email Address'
					htmlFor='email'
					type='email'
					inputRef={register({
						required: 'Email field is required',
					})}
					errorText={
						(errors.email && errors.email.message) ||
						(hasError && 'Email already registered')
					}
				/>
				<Input
					name='password'
					label='Password'
					htmlFor='password'
					type='password'
					inputRef={register({
						required: 'Password field is required',
					})}
					isPassword
					errorText={errors.password && errors.password.message}
				/>
				<Input
					name='confirmPassword'
					label='Confirm Password'
					htmlFor='confirmPassword'
					type='password'
					inputRef={register({
						required: 'Confirm Password field is required',
					})}
					isPassword
					errorText={
						(errors.confirmPassword &&
							errors.confirmPassword.message) ||
						passwordMatchError
					}
				/>
				<div className={styles.bottomModal}>
					<input
						type='file'
						name='myImage'
						accept='image/x-png,image/jpeg'
						onChange={imageFile}
						className={styles.fileInput}
					/>
					<Button
						buttonAlias='Primary'
						buttonText='Save'
						disabled={loadingImage}
						loading={loadingImage}
					/>
				</div>
			</form>
		</Modal>
	)
}

AddModal.propTypes = {
	visible: PropTypes.bool.isRequired,

	addClose: PropTypes.func.isRequired,
	addSubmitted: PropTypes.func.isRequired,
	hasError: PropTypes.bool.isRequired,
}

export default AddModal
