import React from 'react'
import { Row, Col, Hidden } from 'react-grid-system'
import { useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { BackgroundImage, Font, Button, Input } from '../../../components'
import { requestResetPassword } from '../actions'
import { AppState } from '../../../store/constants'
import { selectError, selectAppState } from '../selector'
import AlertliHeader from '../helper/Logo'

import styles from '../index.module.css'

function ResetPassword() {
	const dispatch = useDispatch()

	const appError = useSelector(selectError)
	const appState = useSelector(selectAppState)

	const { pathname } = useLocation()

	const routeStr = pathname.split('/')
	const token = routeStr[routeStr.length - 1]

	const { register, handleSubmit, errors, watch } = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
	})

	const passwordError =
		watch('password') !== watch('confirmPassword')
			? 'Password fields did not match'
			: ''

	const onSubmit = (data) => {
		if (!passwordError) {
			dispatch(
				requestResetPassword({
					password: data.password,
					token,
				}),
			)
		}
	}

	return (
		<Row>
			<Col md={6} lg={7}>
				<Hidden xs sm>
					<BackgroundImage />
				</Hidden>
			</Col>
			<Col md={6} lg={5}>
				<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
					<AlertliHeader />
					<Font fontText='Reset Password' fontAlias='H1' />
					<br />
					<Font fontAlias='P1' fontText='Enter your new password.' />
					<div className={styles.spacingTopBtm} />
					<Input
						name='password'
						type='password'
						htmlFor='password'
						label='New Password'
						inputRef={register({
							required: 'Password field is required',
						})}
						isPassword
						errorText={errors.password && errors.password.message}
					/>
					<Input
						name='confirmPassword'
						type='password'
						htmlFor='confirmPassword'
						label='Confirm New Password'
						inputRef={register({
							required: 'Confirm Password field is required',
						})}
						isPassword
						errorText={
							(errors.confirmPassword &&
								errors.confirmPassword.message) ||
							passwordError ||
							appError
						}
					/>
					<Button
						buttonText='Continue'
						buttonAlias='Primary'
						loading={appState === AppState.LOADING}
						disabled={appState === AppState.LOADING}
					/>
				</form>
			</Col>
		</Row>
	)
}

export default ResetPassword
