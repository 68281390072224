/* eslint-disable no-console */
import { all, call, takeLatest, put, select } from 'redux-saga/effects'

import { AppState } from '../../store/constants'
import { AdminsActions } from './constants'
import {
	apiGetAdmins,
	apiUpdateModerator,
	apiAddModerator,
} from '../../store/api'
import {
	setAdmins,
	setAppState,
	setChangeAdmin,
	requestAdmins as requestAdminsAction,
	setError,
} from './actions'
import { selectQuery } from './selector'

function* requestAdmins() {
	try {
		yield put(setAppState(AppState.LOADING))
		const query = yield select(selectQuery)

		let buildQuery = `?page=${query.currentPage || 1}`

		buildQuery = query.searchTerm
			? `${buildQuery}&search=${query.searchTerm}`
			: buildQuery

		const res = yield call(apiGetAdmins, buildQuery)
		const { data } = res
		yield put(
			setAdmins({
				list: data.results,
				count: data.count,
				next: data.next,
				previous: data.previous,
			}),
		)
	} catch (err) {
		console.log('Request admins', err)
	} finally {
		yield put(setAppState(AppState.READY))
	}
}

function* requestChangeAdminStatus({ payload }) {
	try {
		const fD = { is_active: payload.status }
		const res = yield call(apiUpdateModerator, payload.id, fD)
		const { data } = res.data
		yield put(setChangeAdmin(data))
	} catch (err) {
		console.log('requestChangeAdminStatus', err)
	}
}

function* requestChangeAdmin({ payload }) {
	try {
		let fD = {
			email: payload.email,
			userprofile: {
				full_name: payload.name,
			},
		}

		if (payload.password) {
			fD = {
				...fD,
				password: payload.password,
			}
		}

		if (payload.picture) {
			fD = {
				...fD,
				userprofile: {
					...fD.userprofile,
					picture: payload.picture,
				},
			}
		}

		const res = yield call(apiUpdateModerator, payload.id, fD)
		const { data } = res.data
		yield put(setChangeAdmin(data))
	} catch (err) {
		console.log('requestChangeAdmin', err)
	}
}

function* requestAddModerator({ payload, cb }) {
	try {
		const fD = new FormData()
		fD.append('username', payload.email)
		fD.append('email', payload.email)
		fD.append('password', payload.password)
		fD.append('confirm_password', payload.password)
		fD.append('full_name', payload.name)
		fD.append('picture', payload.picture)
		yield call(apiAddModerator, fD)
		yield put(requestAdminsAction())
		yield call(cb)
	} catch (err) {
		yield put(setError())
	}
}

export default function* () {
	yield all([
		yield takeLatest(AdminsActions.requestAdmins, requestAdmins),
		yield takeLatest(
			AdminsActions.requestChangeAdminStatus,
			requestChangeAdminStatus,
		),
		yield takeLatest(AdminsActions.requestChangeAdmin, requestChangeAdmin),
		yield takeLatest(
			AdminsActions.requestAddModerator,
			requestAddModerator,
		),
	])
}
