/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect'

import {
	transformStories,
	transformPagination,
	transformStory,
	transformComments,
	transformCommentQuery,
} from './transform'

export const selectAppState = createSelector(
	(state) => state.stories,
	(stories) => stories.appState,
)

export const selectStories = createSelector(
	(state) => state.stories,
	(stories) => transformStories(stories.stories),
)

export const selectPagination = createSelector(
	(state) => state.stories,
	(stories) => transformPagination(stories),
)

export const selectQuery = createSelector(
	(state) => state.stories,
	(stories) => stories.query,
)

export const selectStory = createSelector(
	(state) => state.stories,
	(stories) => transformStory(stories.detail.story),
)

export const selectCommentQuery = createSelector(
	(state) => state.stories,
	(stories) => transformCommentQuery(stories.detail),
)

export const selectComments = createSelector(
	(state) => state.stories,
	(stories) => transformComments(stories.detail.comments),
)
export const selectTags = createSelector(
	(state) => state.stories,
	(stories) => stories.tags,
)
