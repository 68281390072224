import React from 'react'

import LoadSvg from '../LoadSvg'
import Loader from '../../images/loader-dark.svg'
import styles from './index.module.css'

const FullPageSpinner = () => {
	return (
		<div className={styles.container}>
			<LoadSvg className={styles.spinner} svg={Loader} />
		</div>
	)
}

export default FullPageSpinner
