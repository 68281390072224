/* eslint-disable import/prefer-default-export */
const domain = 'reports'

export const ReportActions = {
	setAppState: `${domain}/setAppState`,

	requestStories: `${domain}/requestStories`,
	setStories: `${domain}/setStories`,

	requestStory: `${domain}/requestStory`,
	setStory: `${domain}/setStory`,

	requestStoryComments: `${domain}/requestStoryComments`,
	setStoryCommentsList: `${domain}/setStoryCommentsList`,
	setStoryCommentsQuery: `${domain}/setStoryCommentsQuery`,

	requestHideUnhideStoryComments: `${domain}/requestHideUnhideStoryComments`,
	settHideUnhideStoryComments: `${domain}/settHideUnhideStoryComments`,

	requestHideStory: `${domain}/requestHideStory`,
	setHideStory: `${domain}/setHideStory`,
	requestMarkStorySafe: `${domain}/requestMarkStorySafe`,
	setMarkStorySafe: `${domain}/setMarkStorySafe`,

	resetStoryDetail: `${domain}/resetStoryDetail`,

	requestUsers: `${domain}/requestUsers`,
	setUsers: `${domain}/setUsers`,

	requestUser: `${domain}/requestUser`,
	setUser: `${domain}/setUser`,
	requestHideUser: `${domain}/requestHideUser`,
	setHideUser: `${domain}/setHideUser`,

	requestComments: `${domain}/requestComments`,
	setComments: `${domain}/setComments`,

	requestChangeCommentState: `${domain}/requestChangeCommentState`,
	setCommentState: `${domain}/setCommentState`,

	requestUsersReported: `${domain}/requestUsersReported`,
	setUsersReported: `${domain}/setUsersReported`,

	setQuery: `${domain}/setQuery`,
}
