/* eslint-disable import/prefer-default-export */
const domain = 'main'

export const MainActions = {
	setUser: `${domain}/setUser`,

	setAppState: `${domain}/setAppState`,

	requestUserProfile: `${domain}/requestUserProfile`,

	setLogout: `${domain}/setLogout`,

	requestChangePassword: `${domain}/requestChangePassword`,
}
