const domain = 'auth'

export const AuthActions = {
	requestLogin: `${domain}/requestLogin`,

	setError: `${domain}/setError`,
	clearError: `${domain}/clearError`,

	setAppState: `${domain}/setAppState`,

	requestForgotPassword: `${domain}/requestForgotPassword`,

	requestResetPassword: `${domain}/requestResetPassword`,
}

export const abc = 'abc'
