import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, Container, useScreenClass } from 'react-grid-system'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, debounce } from 'lodash'
import { useHistory } from 'react-router-dom'

import {
	Filter,
	Story,
	Search,
	Pagination,
	Spinner,
	SortDataList,
} from '../../../components'

import { trimCharacters, useQuery } from '../../../utils'
import { requestStories, setBuildQuery, requestTags } from '../actions'
import {
	selectStories,
	selectAppState,
	selectPagination,
	selectQuery,
	selectTags,
} from '../selector'
import { AppState } from '../../../store/constants'

import styles from './index.module.css'

const Stories = () => {
	const dispatch = useDispatch()
	const stories = useSelector(selectStories)
	const appState = useSelector(selectAppState)
	const pagination = useSelector(selectPagination)
	const currentQuery = useSelector(selectQuery)
	const tags = useSelector(selectTags)

	const query = useQuery()
	const history = useHistory()

	const sortDataRef = useRef(null)
	const screenClassSortData = useScreenClass(sortDataRef)

	const [selectedTag, setSelectedTag] = useState('')
	const [dateType, selectDateType] = useState('ALL')

	useEffect(() => {
		const currentPage = Number(query.get('page')) || 1
		const title = query.get('title') || ''
		const filterTag = query.get('tag') || ''
		const startDate = query.get('startDate') || ''
		const endDate = query.get('endDate') || ''

		dispatch(
			setBuildQuery({
				currentPage,
				filterTag,
				startDate,
				endDate,
				title,
			}),
		)
		setSelectedTag(filterTag)
		dispatch(requestTags())
		dispatch(requestStories())
	}, [dispatch])

	useEffect(() => {
		let buildQuery = `?page=${currentQuery.currentPage}`
		buildQuery = currentQuery.title
			? `${buildQuery}&title=${currentQuery.title}`
			: buildQuery
		buildQuery = currentQuery.filterTag
			? `${buildQuery}&tag=${currentQuery.filterTag}`
			: buildQuery
		buildQuery = currentQuery.startDate
			? `${buildQuery}&startDate=${currentQuery.startDate}`
			: buildQuery
		buildQuery = currentQuery.endDate
			? `${buildQuery}&endDate=${currentQuery.endDate}`
			: buildQuery

		history.replace(`/stories${buildQuery}`)
	}, [currentQuery, history])

	if (appState === AppState.IDLE || appState === AppState.LOADING) {
		return <Spinner />
	}

	const paginatorClick = (type) => {
		if (type === 'NEXT') {
			dispatch(
				setBuildQuery({
					...currentQuery,
					currentPage: (currentQuery.currentPage += 1),
				}),
			)
		} else if (type === 'PREV') {
			dispatch(
				setBuildQuery({
					...currentQuery,
					currentPage: (currentQuery.currentPage -= 1),
				}),
			)
		} else {
			dispatch(
				setBuildQuery({
					...currentQuery,
					currentPage: Number(type),
				}),
			)
		}
		dispatch(requestStories())
	}

	const tagClick = (name) => {
		dispatch(
			setBuildQuery({
				...currentQuery,
				filterTag: name,
			}),
		)
		dispatch(requestStories())
		setSelectedTag(name)
	}

	const timeQueryClick = (startDate, endDate, type) => {
		dispatch(
			setBuildQuery({
				...currentQuery,
				startDate,
				endDate,
			}),
		)
		selectDateType(type)
		dispatch(requestStories())
	}

	const doSearch = debounce((text) => {
		dispatch(
			setBuildQuery({
				...currentQuery,
				title: text,
			}),
		)
		dispatch(requestStories())
	}, 500)

	const renderStories = () => {
		if (isEmpty(stories)) {
			return (
				<Col sm={12} className={styles.extraSpace}>
					<h2>Alertli currently does not have any stories</h2>
				</Col>
			)
		}

		return stories.map((story) => {
			return (
				<Col sm={6} md={6} lg={4} key={`story-${story.id}`}>
					<Story
						title={trimCharacters(story.title, 50)}
						date={story.date}
						anonymous={story.anonymous}
						storyId={story.id}
						userId={story.user.id}
						username={story.user.name}
						userAvatar={story.user.avatar}
						isViewed={story.isViewed}
						isEdited={story.isEdited}
						storyImage={story.image}
					/>
				</Col>
			)
		})
	}

	return (
		<>
			<Search doSearch={doSearch} value={currentQuery.title} />
			<Container>
				<Row className={styles.searchCriteria}>
					{['lg', 'md', 'xl'].includes(screenClassSortData) && (
						<Col md={6} className={styles.positionInitial}>
							<Filter
								data={tags}
								selectedValue={selectedTag}
								tagClick={tagClick}
							/>
						</Col>
					)}
					<Col
						md={6}
						sm={12}
						ref={sortDataRef}
						className={
							['lg', 'md', 'xl'].includes(screenClassSortData)
								? ''
								: styles.sortContainer
						}
					>
						<SortDataList
							screenSize={screenClassSortData}
							timeClick={timeQueryClick}
							activeType={dateType}
						/>
					</Col>
					{['sm', 'xs'].includes(screenClassSortData) && (
						<Col sm={12} className={styles.positionInitial}>
							<Filter
								data={tags}
								selectedValue={selectedTag}
								tagClick={tagClick}
								full
							/>
						</Col>
					)}
				</Row>
				<Row>{renderStories()}</Row>
				{!isEmpty(stories) && (
					<>
						<br />
						<Row className={styles.marginBtm}>
							<Col sm={12}>
								<Pagination
									next={pagination.next}
									previous={pagination.previous}
									onClick={paginatorClick}
									totalCount={pagination.count}
									perPageCount={12}
									activePage={currentQuery.currentPage}
								/>
							</Col>
						</Row>
					</>
				)}
			</Container>
		</>
	)
}

export default Stories
