import React, { useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.css'

const OverlayModal = ({ children, visible, closeModal }) => {
	const overlayContainer = useRef(null)

	const handleClickOutside = useCallback(
		(e) => {
			if (
				overlayContainer.current &&
				!overlayContainer.current.contains(e.target)
			) {
				closeModal()
			}
		},
		[closeModal],
	)

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [handleClickOutside])

	if (!visible) {
		return <></>
	}

	return (
		<div className={styles.overlay}>
			<div
				className={`${styles.card} overlay-modal`}
				ref={overlayContainer}
			>
				{children}
			</div>
		</div>
	)
}

OverlayModal.propTypes = {
	visible: PropTypes.bool.isRequired,
	children: PropTypes.node.isRequired,

	closeModal: PropTypes.func.isRequired,
}

export default OverlayModal
