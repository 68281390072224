import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container } from 'react-grid-system'
import { isEmpty, debounce } from 'lodash'
import { useHistory } from 'react-router-dom'

import {
	Spinner,
	Font,
	AdminTable,
	Button,
	Search,
	Pagination,
} from '../../../components'
import { useQuery } from '../../../utils'

import {
	requestAdmins,
	setCurrentPage,
	requestChangeAdminStatus,
	requestChangeAdmin,
	requestAddModerator,
	clearError,
} from '../actions'
import {
	selectAppState,
	selectAdmins,
	selectQuery,
	selectError,
} from '../selector'
import EditModal from './helpers/EditModal'
import AddModal from './helpers/AddModal'
import { AppState } from '../../../store/constants'

import styles from './index.module.css'

const Admins = () => {
	const dispatch = useDispatch()

	const appState = useSelector(selectAppState)
	const admins = useSelector(selectAdmins)
	const query = useSelector(selectQuery)
	const hasError = useSelector(selectError)
	const pageQuery = useQuery()
	const history = useHistory()

	const [selectedAdmin, setSelectedAdmin] = useState(null)
	const [editModal, setEditModal] = useState(false)

	const [addModal, setAddModal] = useState(false)

	useEffect(() => {
		const currentPage = Number(pageQuery.get('page')) || 1
		const searchTerm = pageQuery.get('full_name') || ''
		dispatch(
			setCurrentPage({
				...query,
				searchTerm,
				currentPage,
			}),
		)
		dispatch(requestAdmins())
	}, [dispatch])

	useEffect(() => {
		let buildQuery = `?page=${query.currentPage || 1}`

		buildQuery = query.searchTerm
			? `${buildQuery}&full_name=${query.searchTerm}`
			: buildQuery

		history.replace(`/admins${buildQuery}`)
	}, [query])

	if (appState === AppState.IDLE || appState === AppState.LOADING) {
		return <Spinner />
	}

	const paginatorClick = (type) => {
		if (type === 'NEXT') {
			//
			dispatch(
				setCurrentPage({
					...query,
					currentPage: query.currentPage + 1,
				}),
			)
		} else if (type === 'PREV') {
			dispatch(
				setCurrentPage({
					...query,
					currentPage: query.currentPage - 1,
				}),
			)
		} else {
			dispatch(
				setCurrentPage({
					...query,
					currentPage: type,
				}),
			)
		}
		dispatch(requestAdmins())
	}

	const selectChange = (id, state) => {
		dispatch(requestChangeAdminStatus({ status: state, id }))
	}

	const editSubmitted = (data) => {
		dispatch(
			requestChangeAdmin({
				id: selectedAdmin.id,
				name: data.name,
				email: data.email,
				password: data.password,
				picture: data.picture,
			}),
		)
		setEditModal(false)
		setSelectedAdmin(null)
	}

	const editClose = () => {
		setEditModal(false)
		setSelectedAdmin(null)
	}

	const addSubmitted = (data) => {
		dispatch(
			requestAddModerator(data, () => {
				setAddModal(false)
				dispatch(clearError())
			}),
		)
	}

	const addClose = () => {
		setAddModal(false)
		dispatch(clearError())
	}

	const doSearch = debounce((term) => {
		dispatch(
			setCurrentPage({
				...query,
				searchTerm: term,
			}),
		)
		dispatch(requestAdmins())
	}, 500)

	return (
		<>
			<Search doSearch={doSearch} value={query.searchTerm} />
			<Container>
				<Row>
					<Col
						xs={6}
						sm={6}
						md={6}
						lg={6}
						xl={6}
						className={styles.headingCenter}
					>
						<Font fontAlias='H2' fontText='All Admins' />
					</Col>
					<Col xs={6} sm={6} md={6} lg={6} xl={6}>
						<Button
							type='button'
							buttonAlias='Primary'
							buttonText='Add Admin'
							onClick={() => {
								setAddModal(true)
							}}
							className={styles.button}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						{isEmpty(admins) ? (
							<Font fontAlias='P1' fontText='No current admins' />
						) : (
							<AdminTable
								data={admins}
								selectAdmin={(admin) => {
									setEditModal(true)
									setSelectedAdmin(admin)
								}}
								selectChange={selectChange}
							/>
						)}
					</Col>
				</Row>
				{!isEmpty(admins) && (
					<Row>
						<Col sm={12}>
							<Pagination
								next={query.next}
								previous={query.previous}
								onClick={paginatorClick}
								totalCount={query.count}
								perPageCount={10}
								activePage={query.currentPage}
							/>
						</Col>
					</Row>
				)}
				<br />
				<br />
				<br />
			</Container>
			<EditModal
				visible={editModal}
				selectedAdmin={selectedAdmin}
				editSubmitted={editSubmitted}
				editClose={editClose}
			/>
			<AddModal
				visible={addModal}
				addClose={addClose}
				addSubmitted={addSubmitted}
				hasError={hasError}
			/>
		</>
	)
}

Admins.propTypes = {}

export default Admins
