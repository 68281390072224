import { AppState } from '../../store/constants'
import { ReportActions } from './constants'

const initialState = {
	appState: AppState.IDLE,

	stories: {
		list: [],
		story: {},
	},
	users: {
		list: [],
		user: {},
	},
	comments: {
		list: [],
	},

	storyComments: {
		list: [],
		commentPage: 1,
		commentLoading: AppState.LOADING,
		canGoNext: true,
		commentCount: 0,
	},

	query: {
		next: null,
		previous: null,
		currentPage: 1,
		count: 0,
		searchTerm: '',
	},

	reportedBy: [],

	error: '',
}

export default (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case ReportActions.setAppState: {
			return {
				...state,
				appState: payload,
			}
		}
		case ReportActions.setQuery: {
			return {
				...state,
				query: {
					...state.query,
					...payload,
				},
			}
		}
		//
		case ReportActions.setStories: {
			return {
				...state,
				stories: {
					...state.stories,
					list: payload,
				},
			}
		}
		case ReportActions.setStory: {
			return {
				...state,
				stories: {
					...state.stories,
					story: payload,
				},
			}
		}
		case ReportActions.setHideStory: {
			return {
				...state,
				stories: {
					...state.stories,
					story: {
						...state.stories.story,
						status: 'Inactive',
					},
				},
			}
		}
		case ReportActions.setMarkStorySafe: {
			return {
				...state,
				stories: {
					...state.stories,
					story: {
						...state.stories.story,
						story_admin_action: [{ action: 'Mark As Safe' }],
					},
				},
			}
		}
		case ReportActions.resetStoryDetail: {
			return {
				...state,
				stories: {
					...state.stories,
					story: {},
				},
				storyComments: {
					list: [],
					commentPage: 1,
					commentLoading: AppState.LOADING,
					canGoNext: true,
					commentCount: 0,
				},
			}
		}
		//
		case ReportActions.setStoryCommentsQuery: {
			return {
				...state,
				storyComments: {
					...state.storyComments,
					commentPage: payload.commentPage,
					commentLoading: payload.commentLoading,
					canGoNext: payload.canGoNext,
					commentCount: payload.commentCount,
				},
			}
		}
		case ReportActions.setStoryCommentsList: {
			return {
				...state,
				storyComments: {
					...state.storyComments,
					list: [...state.storyComments.list, ...payload],
				},
			}
		}
		case ReportActions.settHideUnhideStoryComments: {
			const list = state.storyComments.list.map((comment) => {
				if (comment.id === payload.id) {
					return {
						...comment,
						status: payload.status,
					}
				}
				return comment
			})
			return {
				...state,
				storyComments: {
					...state.storyComments,
					list,
				},
			}
		}
		//
		case ReportActions.setUsers: {
			return {
				...state,
				users: {
					...state.users,
					list: payload,
				},
			}
		}
		case ReportActions.setUser: {
			return {
				...state,
				users: {
					...state.users,
					user: payload,
				},
			}
		}
		case ReportActions.setHideUser: {
			const users = state.users.list.map((user) => {
				if (user.reported_user === payload.id) {
					return {
						...user,
						is_active: payload.is_active,
					}
				}
				return user
			})
			return {
				...state,
				users: {
					list: users,
				},
			}
		}
		//
		case ReportActions.setComments: {
			return {
				...state,
				comments: {
					...state.comments,
					list: payload,
				},
			}
		}
		case ReportActions.setCommentState: {
			const list = state.comments.list.map((comment) => {
				if (comment.reported_comment === payload.id) {
					return {
						...comment,
						status: payload.state,
					}
				}
				return comment
			})
			return {
				...state,
				comments: {
					...state.comments,
					list,
				},
			}
		}
		//
		case ReportActions.setUsersReported: {
			return {
				...state,
				reportedBy: payload,
			}
		}
		default: {
			return state
		}
	}
}
