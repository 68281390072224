/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.css'
import arrowIcon from '../../images/chevron-right.png'

const Pagination = ({
	next,
	previous,
	onClick,
	totalCount,
	perPageCount,
	activePage,
}) => {
	const pages = Math.ceil(totalCount / perPageCount)

	const renderLinks = () => {
		let jsx = []
		for (let i = 1; i <= activePage; i += 1) {
			jsx.push(
				<li
					onClick={() => onClick(i)}
					key={`pagination-${i}`}
					className={activePage === i ? styles.activeItem : ''}
				>
					{i}
				</li>,
			)
		}
		if (activePage > 5) {
			jsx = jsx.slice(activePage - 5, activePage)
		}
		return jsx
	}

	return (
		<div>
			<ul className={styles.pagination}>
				{previous && (
					<li onClick={() => onClick('PREV')}>
						<img src={arrowIcon} alt='Arrow' />
					</li>
				)}
				{renderLinks()}
				{activePage !== pages - 1 && next && (
					<>
						<li>...</li>
					</>
				)}
				{activePage !== pages && (
					<>
						<li onClick={() => onClick(pages)}>{pages}</li>
					</>
				)}
				{next && (
					<li onClick={() => onClick('NEXT')}>
						<img
							className={styles.nextArrowIcon}
							src={arrowIcon}
							alt='Arrow'
						/>
					</li>
				)}
			</ul>
		</div>
	)
}

Pagination.propTypes = {
	next: PropTypes.string,
	previous: PropTypes.string,

	totalCount: PropTypes.number,
	perPageCount: PropTypes.number,
	activePage: PropTypes.number,

	onClick: PropTypes.func.isRequired,
}

Pagination.defaultProps = {
	next: null,
	previous: null,
	totalCount: 1,
	perPageCount: 1,
	activePage: 1,
}

export default Pagination
