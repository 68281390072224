/* eslint-disable import/prefer-default-export */
const domain = 'users'

export const Actions = {
	setAppState: `${domain}/setAppState`,

	setQuery: `${domain}/setQuery`,
	clearQuery: `${domain}/clearQuery`,

	requestUsers: `${domain}/requestUsers`,
	setUsers: `${domain}/setUsers`,

	requestHideUser: `${domain}/requestHideUser`,
	setHideUser: `${domain}/setHideUser`,

	requestUser: `${domain}/requestUser`,
	setUser: `${domain}/setUser`,

	requestStories: `${domain}/requestStories`,
	setStories: `${domain}/setStories`,
}
