import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.css'

const Font = ({ fontText, fontAlias, className }) => {
	if (fontAlias === 'H1') {
		return (
			<div className={className}>
				<h1>{fontText}</h1>
			</div>
		)
	}

	if (fontAlias === 'H2') {
		return (
			<div className={className}>
				<h2>{fontText}</h2>
			</div>
		)
	}

	if (fontAlias === 'H3') {
		return (
			<div className={className}>
				<h3>{fontText}</h3>
			</div>
		)
	}
	if (fontAlias === 'H4') {
		return (
			<div className={className}>
				<h4>{fontText}</h4>
			</div>
		)
	}
	if (fontAlias === 'H5') {
		return (
			<div className={className}>
				<h5>{fontText}</h5>
			</div>
		)
	}

	if (fontAlias === 'P1') {
		return (
			<div className={className}>
				<p>{fontText}</p>
			</div>
		)
	}

	if (fontAlias === 'P2') {
		return (
			<div className={className}>
				<p className={styles.primaryPara}>{fontText}</p>
			</div>
		)
	}

	if (fontAlias === 'ERROR') {
		return (
			<div>
				<p className={styles.error}>{fontText}</p>
			</div>
		)
	}

	return <></>
}

Font.propTypes = {
	fontText: PropTypes.string.isRequired,
	fontAlias: PropTypes.oneOf(['H1', 'H2', 'H3', 'P1', 'P2', 'ERROR']),

	className: PropTypes.string,
}

Font.defaultProps = {
	fontAlias: 'H1',
	className: '',
}

export default Font
