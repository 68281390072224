import { Actions } from './constants'

export const setAppState = (payload) => ({
	type: Actions.setAppState,
	payload,
})

export const setQuery = (payload) => ({
	type: Actions.setQuery,
	payload,
})

export const clearQuery = () => ({
	type: Actions.clearQuery,
})

//
export const requestUsers = () => ({
	type: Actions.requestUsers,
})

export const setUsers = (payload) => ({
	type: Actions.setUsers,
	payload,
})

//

export const requestHideUser = (payload) => ({
	type: Actions.requestHideUser,
	payload,
})

export const setHideUser = (payload) => ({
	type: Actions.setHideUser,
	payload,
})

//

export const requestUser = (payload) => ({
	type: Actions.requestUser,
	payload,
})

export const setUser = (payload) => ({
	type: Actions.setUser,
	payload,
})

//

export const requestStories = (payload) => ({
	type: Actions.requestStories,
	payload,
})

export const setStories = (payload) => ({
	type: Actions.setStories,
	payload,
})
