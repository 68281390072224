import { MainActions } from './constants'
import { AppState } from '../constants'

const initialState = {
	user: {
		token: '',
		id: 0,
		firstName: '',
		lastName: '',
		fullName: '',
		picture: '',
		superUser: false,
	},
	isAuthenticated: false,
	appState: AppState.IDLE,
}

export default (state = initialState, action) => {
	const { type, payload } = action

	switch (type) {
		case MainActions.setUser: {
			return {
				...state,
				isAuthenticated: true,
				user: payload,
			}
		}
		case MainActions.setAppState: {
			return {
				...state,
				appState: payload,
			}
		}
		case MainActions.setLogout: {
			return {
				...initialState,
			}
		}
		default: {
			return state
		}
	}
}
