import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styles from './index.module.css'
import PlaceholderAvatar from '../../images/placeholder.png'

const Story = ({
	username,
	userId,
	title,
	date,
	storyId,
	userAvatar,
	isViewed,
	isEdited,
	anonymous,
	storyImage,
	link,
	bold,
	count,
}) => {
	return (
		<div className={styles.storyContainer}>
			{/*  */}
			<div className={styles.imgContainer}>
				<img
					className={styles.img}
					src={storyImage}
					alt='Story media link not supported'
				/>
				<div className={styles.overlayImgContainer} />
				<div className={styles.profileContainer}>
					{anonymous ? (
						<>
							<img
								src={PlaceholderAvatar}
								alt={`Profile ${username}`}
							/>
							<div className={styles.userDetail}>
								<h5 className={styles.userName}>
									Anonymous User ( {username} )
								</h5>
								<Link to={`/users/${userId}`}>
									View Profile
								</Link>
							</div>
						</>
					) : (
						<>
							<img
								src={userAvatar || PlaceholderAvatar}
								alt={`Profile ${username}`}
							/>

							<div className={styles.userDetail}>
								<h5 className={styles.userName}>{username}</h5>
								<Link to={`/users/${userId}`}>
									View Profile
								</Link>
							</div>
						</>
					)}
				</div>
			</div>
			{/*  */}
			<div className={styles.storyDetail}>
				<div className={styles.storyText}>
					{bold ? (
						<label className={styles.storyLabel}>
							{count} <span>{title}</span>
						</label>
					) : (
						<label className={styles.storyLabel}>{title}</label>
					)}
					<span className={styles.dateText}>{date}</span>
				</div>
				{link === '' ? (
					<>
						<Link
							className={styles.detailLink}
							to={`/stories/${storyId}`}
						>
							View Story
						</Link>
					</>
				) : (
					<>
						<Link className={styles.detailLink} to={link}>
							View Story
						</Link>
					</>
				)}
			</div>
			<div className={styles.viewedButton}>
				{isEdited && (
					<Link
						className={styles.detailLinkBlue}
						to={`/stories/${storyId}`}
					>
						EDITED
					</Link>
				)}
				{isViewed && (
					<Link
						className={`${styles.viewedLinkYellow} ${styles.ml5}`}
						to={`/stories/${storyId}`}
					>
						VIEWED
					</Link>
				)}
			</div>
		</div>
	)
}

Story.propTypes = {
	title: PropTypes.string.isRequired,
	date: PropTypes.string.isRequired,
	storyId: PropTypes.number.isRequired,
	anonymous: PropTypes.bool.isRequired,
	storyImage: PropTypes.string.isRequired,

	username: PropTypes.string,
	userId: PropTypes.number,
	userAvatar: PropTypes.string,
	isViewed: PropTypes.bool,
	isEdited: PropTypes.bool,
	bold: PropTypes.bool,
	count: PropTypes.string,
	link: PropTypes.string,
}

Story.defaultProps = {
	username: '',
	userId: 0,
	userAvatar: 0,
	bold: false,
	count: 0,
	link: '',
	isViewed: false,
	isEdited: false,
}

export default Story
