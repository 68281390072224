/* eslint-disable camelcase */
import { isEmpty } from 'lodash'
import moment from 'moment'

export const transformUsers = (users) => {
	if (isEmpty(users)) return []

	return users.map((user) => {
		return {
			id: user.id,
			userId: user.id,
			fullName: user.userprofile.full_name,
			picture: user.userprofile.picture,
			email: user.email || '',
			username: user.username,
			isActive: user.is_active,
			signups_today: user.signups_today,
			signups_yesterday: user.signups_yesterday,
		}
	})
}

export const transformUser = (user) => {
	if (isEmpty(user)) {
		return {}
	}

	return {
		id: user.id,
		name: user.userprofile.full_name,
		picture: user.userprofile.picture || '',
		mobile: user.userprofile.mobile,
		email: user.email || '',
		isActive: user.is_active,
		totalPosts: user.post,
	}
}

export const transformStories = (stories) => {
	return stories.map((story) => {
		let date = moment(story.created_at) || ''
		if (date) {
			date = date.format('DD MMMM YYYY')
		}
		return {
			id: story.id,
			// anonymous: story.anonymous_user,
			isViewed: story.admin_visited,
			isEdited: story.edited,
			user: {
				id: story.user.id || 0,
				name: story.user?.userprofile?.full_name || '',
				avatar: story.user?.userprofile?.picture || '',
			},
			title: story.title,
			reportCount: story.story_reported_count,
			image: story.thumbnail,
			date,
		}
	})
}
