import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { Font, Spinner } from '../../../components'
import Media from './helpers/Media'
import UserSection from './helpers/UserSection'
import DetailSection from './helpers/DetailSection'
import ReactionSection from './helpers/ReactionSection'
import CommentList from './helpers/CommentList'
import VerificationList from './helpers/VerificationList'

import { selectStory, selectAppState } from '../selector'
import {
	requestGetStory,
	resetStoryDetail,
	requestHideStory,
	requestStoryMarkSafe,
} from '../actions'
import { dateFromNow } from '../../../utils'
import { AppState } from '../../../store/constants'

import BackButton from '../../../images/back-button.svg'
import Shield from '../../../images/shield.png'
import styles from './index.module.css'

const StoryDetail = () => {
	const params = useParams()
	const dispatch = useDispatch()
	const appState = useSelector(selectAppState)
	const story = useSelector(selectStory)
	const history = useHistory()

	useEffect(() => {
		dispatch(requestGetStory(params.id))
	}, [params.id, dispatch])

	useEffect(() => {
		return () => {
			dispatch(resetStoryDetail())
		}
	}, [dispatch])

	const changeStoryStatus = ({ value }) => {
		if (value === 'hide') {
			dispatch(requestHideStory(story.id))
			return
		}

		if (value === 'markSafe') {
			dispatch(requestStoryMarkSafe(story.id))
		}
	}

	if (appState === AppState.LOADING || appState === AppState.IDLE) {
		return <Spinner />
	}

	const renderVerification = () => {
		if (isEmpty(story.verifications)) {
			return (
				<div className={styles.emptyVerification}>
					<Col sm={12}>
						<Font fontAlias='H2' fontText='Verifications' />
					</Col>
					<div className={styles.verifyEmptyContainer}>
						<img
							src={Shield}
							alt='Comment'
							className={styles.logo}
						/>
						<h2 style={{ color: '#9ea2a6', marginTop: '15px' }}>
							No Story Verifications
						</h2>
					</div>
				</div>
			)
		}
		return (
			<>
				<Col sm={12}>
					<Font fontAlias='H2' fontText='Verifications' />
				</Col>
				<VerificationList data={story.verifications} />
			</>
		)
	}

	return (
		<Container className='top-space'>
			<Row>
				<Col sm={4} className={styles.topSection}>
					<button
						onClick={() => {
							history.goBack()
						}}
						type='button'
						className={styles.linkContainer}
					>
						<img
							src={BackButton}
							className={styles.backSvg}
							alt='Back button'
						/>
						<Font fontAlias='P1' fontText='Back' />
					</button>
				</Col>
			</Row>
			{isEmpty(story) ? (
				<Row>
					<Font
						fontAlias='H2'
						fontText={`Story with id ${params.id} does not exist`}
					/>
				</Row>
			) : (
				<>
					<Row>
						<Media media={story.mediaUrl} type={story.mediaType} />
						<Col md={7} sm={12} className={styles.infoContainer}>
							<UserSection
								location={story.location}
								time={dateFromNow(story.date) || ''}
								user={story.user}
								isAnon={story.anon}
								active={story.active}
								markSafe={story.markSafe}
								changeStoryStatus={changeStoryStatus}
							/>
							<DetailSection
								heading={story.title}
								paragraph={story.description || ''}
							/>
							<br />
							<ReactionSection
								likeCount={story.likes}
								reshareCount={story.share}
								commentCount={story.comments}
							/>
							<br />
							<CommentList storyId={params.id} />
						</Col>
					</Row>
					<Row className={styles.bottomSection}>
						{renderVerification()}
					</Row>
				</>
			)}
		</Container>
	)
}

export default StoryDetail
