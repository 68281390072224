/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import PlaceholderAvatar from '../../images/placeholder.png'
import styles from './index.module.css'

const AdminTable = ({ data, selectChange, forwardLink }) => {
	//
	const setOnErrorImage = (e) => {
		e.currentTarget.src = PlaceholderAvatar
	}

	const renderUsers = () => {
		return data.map((user) => {
			const selectClass = classnames({
				[styles.select]: true,
				[styles.selectRed]: !user.isActive,
			})
			return (
				<tr key={`user-${user.id}`}>
					<td className={styles.userInfo}>
						<img
							alt='user'
							src={user.picture || PlaceholderAvatar}
							onError={setOnErrorImage}
							className={styles.userImage}
						/>
						<div className={styles.userName}>
							{forwardLink === 'REPORTS' ? (
								<Link
									className={styles.textDecorationNone}
									to={`/reports/users/${user.userId}`}
								>
									{user.fullName}
								</Link>
							) : (
								<Link
									className={styles.textDecorationNone}
									to={`/users/${user.userId}`}
								>
									{user.fullName}
								</Link>
							)}
						</div>
					</td>
					<td>{user.username}</td>
					<td>{user.email}</td>
					<td>
						<select
							onChange={(e) => {
								selectChange(user.userId, e.target.value)
							}}
							className={selectClass}
							defaultValue={
								user.isActive ? 'enabled' : 'disabled'
							}
						>
							<option
								value='enabled'
								className={styles.selectBlack}
							>
								Enabled
							</option>
							<option
								value='disabled'
								className={styles.selectRed}
							>
								Blocked
							</option>
						</select>
					</td>
				</tr>
			)
		})
	}

	return (
		<div className={styles.horizontalScroll}>
			<table className={styles.userData}>
				<thead>
					<tr>
						<th>User</th>
						<th>Phone</th>
						<th>Email Address</th>
						<th>Status</th>
					</tr>
				</thead>
				<tbody>{renderUsers()}</tbody>
			</table>
		</div>
	)
}

AdminTable.propTypes = {
	data: PropTypes.array.isRequired,
	selectChange: PropTypes.func.isRequired,
	forwardLink: PropTypes.string,
}

AdminTable.defaultProps = {
	forwardLink: 'REPORTS',
}

export default AdminTable
