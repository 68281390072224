import React from 'react'
import PropTypes from 'prop-types'

const LoadSvg = ({ className, svg }) => {
	return (
		<object type='image/svg+xml' data={svg} className={className}>
			Your browser does not support SVG
		</object>
	)
}

LoadSvg.propTypes = {
	className: PropTypes.string,
	svg: PropTypes.node.isRequired,
}

LoadSvg.defaultProps = {
	className: '',
}

export default LoadSvg
