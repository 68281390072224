/* eslint-disable no-console */
import { all, takeLatest, call, put, select } from 'redux-saga/effects'

import { AppState } from '../../store/constants'
import {
	apiGetStories,
	apiGetStory,
	apiStoryMarkSafe,
	apiGetComments,
	apiHideStory,
	apiUnHideStory,
	apiGetTags,
	apiHideUnhideComment,
} from '../../store/api'
import { StoriesActions } from './constants'
import { selectQuery, selectCommentQuery } from './selector'
import {
	setLoading,
	setStories,
	setStory,
	setTags,
	setStoryCommentsQuery,
	setStoryCommentsList,
	setStoryMarkSafe,
	setUnHideStory,
	setHideStory,
	setBuildQuery,
	setHideUnhideStoryComments,
} from './actions'

function* requestStories() {
	try {
		const query = yield select(selectQuery)
		yield put(setLoading(AppState.LOADING))

		let buildQuery = `?page=${query.currentPage}`
		buildQuery = query.title
			? `${buildQuery}&title=${query.title}`
			: buildQuery
		buildQuery = query.filterTag
			? `${buildQuery}&tag=${query.filterTag}`
			: buildQuery
		buildQuery = query.startDate
			? `${buildQuery}&startDate=${query.startDate}`
			: buildQuery
		buildQuery = query.endDate
			? `${buildQuery}&endDate=${query.endDate}`
			: buildQuery

		const res = yield call(apiGetStories, buildQuery)

		yield put(
			setStories({
				count: res.data.count,
				stories: res.data.results.data,
				next: res.data.next,
				previous: res.data.previous,
			}),
		)
	} catch (err) {
		yield put(
			setStories({
				count: 0,
				stories: [],
				next: null,
				previous: null,
			}),
		)
		yield put(
			setBuildQuery({
				currentPage: 1,
				filterTag: '',
				startDate: '',
				endDate: '',
				title: '',
			}),
		)
		console.log('Get Stories: ', err.response.data)
	} finally {
		yield put(setLoading(AppState.READY))
	}
}

function* requestTags() {
	try {
		const res = yield call(apiGetTags)
		yield put(setTags(res.data))
	} catch (err) {
		console.log('Request tags', err)
	}
}

function* requestGetStory({ payload }) {
	try {
		yield put(setLoading(AppState.LOADING))
		const res = yield call(apiGetStory, payload)
		const { data } = res.data
		yield put(setStory(data))
	} catch (err) {
		console.log('Get Story', err)
	} finally {
		yield put(setLoading(AppState.READY))
	}
}

function* requestStoryComments({ payload }) {
	try {
		const commentQuery = yield select(selectCommentQuery)
		if (commentQuery.canGoNext) {
			const res = yield call(
				apiGetComments,
				payload,
				commentQuery.commentPage,
			)
			const { data } = res

			const nextPage = commentQuery.commentPage + 1
			yield put(
				setStoryCommentsQuery({
					canGoNext: data.next,
					commentLoading: AppState.READY,
					commentPage: nextPage,
					commentCount: data.count,
				}),
			)
			yield put(setStoryCommentsList(data.results.data))
		}
	} catch (err) {
		console.log('Story comments')
	}
}

function* requestHideStory({ payload }) {
	try {
		yield call(apiHideStory, payload)
		yield put(setHideStory())
	} catch (err) {
		console.log('Hide story', err)
	}
}

function* requestUnHideStory({ payload }) {
	try {
		yield call(apiUnHideStory, payload)
		yield put(setUnHideStory())
	} catch (err) {
		console.log('Hide story', err)
	}
}

function* requestStoryMarkSafe({ payload }) {
	try {
		yield call(apiStoryMarkSafe, payload, 'Mark As Safe')
		yield put(setStoryMarkSafe())
	} catch (err) {
		console.log('requestStoryMarkSafe', err)
	}
}

function* requestHideUnhideStoryComments({ payload }) {
	try {
		if (payload.type === 'hide') {
			yield call(apiHideUnhideComment, payload.id, false)
			yield put(
				setHideUnhideStoryComments({ id: payload.id, status: false }),
			)
		} else {
			yield call(apiHideUnhideComment, payload.id, true)
			yield put(
				setHideUnhideStoryComments({ id: payload.id, status: true }),
			)
		}
	} catch (err) {
		console.log('requestHideUnhideStoryComments', err)
	}
}

export default function* () {
	yield all([
		yield takeLatest(StoriesActions.requestStories, requestStories),
		yield takeLatest(StoriesActions.requestGetStory, requestGetStory),
		yield takeLatest(
			StoriesActions.requestStoryComments,
			requestStoryComments,
		),
		yield takeLatest(StoriesActions.requestHideStory, requestHideStory),
		yield takeLatest(StoriesActions.requestUnHideStory, requestUnHideStory),
		yield takeLatest(
			StoriesActions.requestStoryMarkSafe,
			requestStoryMarkSafe,
		),
		yield takeLatest(StoriesActions.requestTags, requestTags),
		yield takeLatest(
			StoriesActions.requestHideUnhideStoryComments,
			requestHideUnhideStoryComments,
		),
	])
}
