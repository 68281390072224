/* eslint-disable no-console */
import { all, takeLatest, call, put, select } from 'redux-saga/effects'

import { AppState } from '../../store/constants'
import {
	apiGetAllUsers,
	apiHideUser,
	apiGetReportedUser,
	apiGetStoriesByUsername,
} from '../../store/api'

import { Actions } from './constants'
import { selectQuery } from './selector'
import {
	setAppState,
	setQuery,
	setUsers,
	setHideUser,
	setUser,
	setStories,
} from './actions'

function* requestUsers() {
	try {
		yield put(setAppState(AppState.LOADING))
		const query = yield select(selectQuery)

		let buildQuery = `?page=${query.currentPage || 1}`
		buildQuery = query.searchTerm
			? `${buildQuery}&search=${query.searchTerm}`
			: buildQuery

		const res = yield call(apiGetAllUsers, buildQuery)

		const { data } = res

		yield put(setUsers(data.results))
		yield put(
			setQuery({
				count: data.count,
				next: data.next,
				previous: data.previous,
			}),
		)
	} catch (err) {
		console.log('requestUsers', err)
	} finally {
		yield put(setAppState(AppState.READY))
	}
}

function* requestHideUser({ payload }) {
	try {
		yield call(apiHideUser, payload.id, payload.isActive)
		yield put(
			setHideUser({
				id: payload.id,
				is_active: payload.isActive,
			}),
		)
		// routerPush('/users')
	} catch (err) {
		console.log('requestHideUser: ', err)
	}
}

function* requestUser({ payload }) {
	try {
		yield put(setAppState(AppState.LOADING))
		const res = yield call(apiGetReportedUser, payload)
		yield put(setUser(res.data))
	} catch (err) {
		console.log('requestHideUser: ', err)
	} finally {
		yield put(setAppState(AppState.READY))
	}
}

function* requestStories({ payload }) {
	try {
		const query = yield select(selectQuery)

		const buildQuery = `?page=${query.currentPage || 1}&user=${payload}`

		const res = yield call(apiGetStoriesByUsername, buildQuery)
		const { data } = res
		yield put(setStories(data.results.data))
		yield put(
			setQuery({
				count: data.count,
				next: data.next,
				previous: data.previous,
			}),
		)
	} catch (err) {
		console.log('requestStories: ', err)
	}
}

export default function* () {
	yield all([
		yield takeLatest(Actions.requestUsers, requestUsers),
		yield takeLatest(Actions.requestHideUser, requestHideUser),
		yield takeLatest(Actions.requestUser, requestUser),
		yield takeLatest(Actions.requestStories, requestStories),
	])
}
