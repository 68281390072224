import { combineReducers } from 'redux'
import mainReducer from './main/reducer'
import authReducer from '../domains/auth/reducer'
import storiesReducer from '../domains/stories/reducer'
import adminsReducer from '../domains/admins/reducer'
import reportsReducer from '../domains/reports/reducer'
import usersReducer from '../domains/users/reducer'
import contributionsReducer from '../domains/contributions/reducer'

export default combineReducers({
	main: mainReducer,
	auth: authReducer,
	stories: storiesReducer,
	admins: adminsReducer,
	contributions: contributionsReducer,
	reports: reportsReducer,
	users: usersReducer,
})
