/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import OptionsImage from '../../images/options.svg'
import styles from './index.module.css'
import Modal from '../Modal'
import Font from '../Font'
import Button from '../Button'

const Select = ({ items, itemClicked, hasModal }) => {
	const buttonRef = useRef(null)
	const [open, setOpen] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [selectedItem, setSelectedItem] = useState(null)

	const handleClickOutside = (event) => {
		if (!event.target.classList.contains('hideActionButton')) {
			setOpen(false)
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	const closeOption = () => {
		setOpen(false)
	}

	const closeModal = () => {
		setOpen(false)
		setShowModal(false)
		setSelectedItem(null)
	}

	const renderItems = () => {
		return items.map((item) => {
			return (
				<li
					key={`option-${item.value}`}
					onClick={() => {
						if (hasModal && item.canOpenModal) {
							setSelectedItem(item)
							setShowModal(true)
						} else {
							closeOption()
							itemClicked(item)
						}
					}}
					className='hideActionButton'
				>
					{item.name}
				</li>
			)
		})
	}

	return (
		<>
			<button
				className={styles.option}
				type='button'
				onClick={() => {
					setOpen(true)
				}}
				ref={buttonRef}
			>
				<img
					className={styles.optionSvg}
					src={OptionsImage}
					alt='Options'
				/>
			</button>
			{open && (
				<ul
					className={`${styles.optionModal} hideActionButton`}
					onClick={() => {
						closeOption()
					}}
				>
					{renderItems()}
				</ul>
			)}
			{showModal && (
				<Modal visible={showModal} closeModal={closeModal}>
					<div className={styles.modalText}>
						<Font
							fontAlias='H2'
							fontText={selectedItem.modalText}
						/>
					</div>
					<div className={styles.buttons}>
						<Button
							buttonAlias='Primary'
							buttonText='Yes'
							type='button'
							className={styles.button}
							onClick={() => {
								itemClicked(selectedItem)
								closeModal()
							}}
						/>
						<Button
							buttonAlias='PrimaryOutline'
							buttonText='No'
							type='button'
							className={styles.button}
							onClick={closeModal}
						/>
					</div>
				</Modal>
			)}
		</>
	)
}

Select.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			modalText: PropTypes.string,
			canOpenModal: PropTypes.bool,
		}),
	).isRequired,
	itemClicked: PropTypes.func.isRequired,

	hasModal: PropTypes.bool,
}

Select.defaultProps = {
	hasModal: true,
}

export default Select
