import axios from 'axios'

import { getToken } from '../utils'

export const setUpAxios = () => {
	axios.defaults.baseURL = process.env.REACT_APP_API_URL

	const token = getToken()
	if (token) {
		// eslint-disable-next-line dot-notation
		axios.defaults.headers.common['Authorization'] = `Token ${token}`
	}
}

export const clearAxios = () => {
	// eslint-disable-next-line dot-notation
	axios.defaults.headers.common['Authorization'] = ``
}

setUpAxios()

export const apiLogin = (data) => {
	return axios.post('admin/login', data)
}

export const apiGetProfile = () => {
	return axios.get('profile')
}

export const apiForgotPassword = (data) => {
	return axios.post('admin/forgotpassword', data)
}

export const apiResetPassword = (data) => {
	return axios.put('admin/resetpassword', data)
}

export const apiGetStory = (storyId) => {
	return axios.get(`admin/story/${storyId}/`)
}

export const apiGetStories = (query) => {
	return axios.get(`admin/stories${query}`)
}

export const apiGetTags = () => {
	return axios.get('tags')
}

export const apiGetComments = (storyId, pageId) => {
	return axios.get(`admin/story/${storyId}/comments?page=${pageId}`)
}

export const apiHideStory = (storyId) => {
	const fD = new FormData()
	fD.append('status', 'Inactive')
	return axios.put(`admin/stories/${storyId}/status`, fD)
}

export const apiUnHideStory = (storyId) => {
	const fD = new FormData()
	fD.append('status', 'Publish')
	return axios.put(`admin/stories/${storyId}/status`, fD)
}

export const apiGetAdmins = (query) => {
	return axios.get(`admin/moderator${query}`)
}

export const apiGetContributions = (query) => {
	return axios.get(`admin/contributions/list${query}`)
}

export const apiUpdateModerator = (id, data) => {
	return axios.put(`admin/moderator/${id}/update`, data)
}

export const apiUpdateContribution = (id, data) => {
	return axios.put(`admin/contribution/${id}/update`, data)
}

export const apiAddModerator = (data) => {
	return axios.post('admin/moderator', data)
}

export const apiGetReportedStories = (query) => {
	return axios.get(`admin/stories/list/report${query}`)
}

export const apiGetReportedUsers = (query) => {
	return axios.get(`admin/users/list/report${query}`)
}

export const apiGetReportedUser = (userId) => {
	return axios.get(`admin/user/${userId}/`)
}

export const apiHideUser = (userId, isActive) => {
	const data = {
		is_active: isActive,
		is_deleted: false,
	}
	return axios.put(`admin/user/${userId}/status`, data)
}

export const apiGetReportedComments = (query) => {
	return axios.get(`admin/comments/list/report${query}`)
}

export const apiHideUnhideComment = (id, type) => {
	const data = {
		status: type ? 'True' : 'False',
	}
	return axios.put(`admin/comments/${id}/status`, data)
}

export const apiGetReportedBy = (id, type = 'story') => {
	return axios.get(`admin/report/tag?type=${type}&id=${id}`)
}

export const apiStoryMarkSafe = (id, action) => {
	const data = {
		story: id,
		type: 'Story',
		action,
	}
	return axios.post('admin/report/action', data)
}

export const apiGetAllUsers = (query) => {
	return axios.get(`admin/users/${query}`)
}

export const apiGetStoriesByUsername = (query) => {
	return axios.get(`admin/list/stories${query}`)
}

export const apiChangePassword = (data) => {
	return axios.put(`admin/changepassword`, data)
}

export const apiAddChallenge = (data) => {
	return axios.post('admin/contributions/create', data)
}
