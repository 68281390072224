import { AdminsActions } from './constants'

export const setAppState = (payload) => ({
	type: AdminsActions.setAppState,
	payload,
})

export const requestAdmins = () => ({
	type: AdminsActions.requestAdmins,
})

export const setAdmins = (payload) => ({
	type: AdminsActions.setAdmins,
	payload,
})

export const setCurrentPage = (payload) => ({
	type: AdminsActions.setCurrentPage,
	payload,
})

export const requestChangeAdminStatus = (payload) => ({
	type: AdminsActions.requestChangeAdminStatus,
	payload,
})

export const requestChangeAdmin = (payload) => ({
	type: AdminsActions.requestChangeAdmin,
	payload,
})

export const setChangeAdmin = (payload) => ({
	type: AdminsActions.setChangeAdmin,
	payload,
})

export const requestAddModerator = (payload, cb) => ({
	type: AdminsActions.requestAddModerator,
	payload,
	cb,
})

export const setError = () => ({ type: AdminsActions.setError })
export const clearError = () => ({ type: AdminsActions.clearError })
