import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import { isEmpty, debounce } from 'lodash'
import { useHistory } from 'react-router-dom'

import { AppState } from '../../../store/constants'
import { Spinner, Pagination, Story, Search } from '../../../components'
import { useQuery } from '../../../utils'

import { requestStories, setQuery } from '../actions'
import { selectAppState, selectStories, selectQuery } from '../selector'
import Header from '../helpers/Header'

const Stories = () => {
	const dispatch = useDispatch()
	const appState = useSelector(selectAppState)
	const stories = useSelector(selectStories)
	const query = useSelector(selectQuery)
	const pageQuery = useQuery()
	const history = useHistory()

	useEffect(() => {
		const currentPage = Number(pageQuery.get('page')) || 1
		const searchTerm = pageQuery.get('title') || ''

		dispatch(
			setQuery({
				...query,
				currentPage,
				searchTerm,
			}),
		)
		dispatch(requestStories())
		return () => {
			dispatch(
				setQuery({
					next: null,
					previous: null,
					currentPage: 1,
					count: 0,
					searchTerm: '',
				}),
			)
		}
	}, [dispatch])

	useEffect(() => {
		let buildQuery = `?page=${query.currentPage}`

		buildQuery = query.searchTerm
			? `${buildQuery}&title=${query.searchTerm}`
			: buildQuery

		history.replace(`/reports/stories${buildQuery}`)
	}, [query])

	if (appState === AppState.IDLE || appState === AppState.LOADING) {
		return <Spinner />
	}

	const paginatorClick = (type) => {
		if (type === 'NEXT') {
			dispatch(
				setQuery({
					currentPage: query.currentPage + 1,
				}),
			)
		} else if (type === 'PREV') {
			dispatch(
				setQuery({
					currentPage: query.currentPage - 1,
				}),
			)
		} else {
			dispatch(
				setQuery({
					currentPage: type,
				}),
			)
		}
		dispatch(requestStories())
	}

	const doSearch = debounce((term) => {
		dispatch(
			setQuery({
				...query,
				searchTerm: term,
			}),
		)
		dispatch(requestStories())
	}, 500)

	const renderStories = () => {
		if (isEmpty(stories)) {
			return (
				<Col sm={12}>
					<h2>Alertli currently does not have any stories</h2>
				</Col>
			)
		}

		return stories.map((story) => {
			return (
				<Col sm={6} md={6} lg={4} key={`story-${story.id}`}>
					<Story
						count={`${story.reportCount} people`}
						title={story.title}
						date={story.date}
						anonymous={story.anonymous}
						storyId={story.storyId}
						userId={story.user.id}
						username={story.user.name}
						userAvatar={story.user.avatar}
						storyImage={story.image}
						isViewed={story.isViewed}
						isEdited={story.isEdited}
						link={`/reports/stories/${story.storyId}`}
					/>
				</Col>
			)
		})
	}

	return (
		<>
			<Search doSearch={doSearch} value={query.searchTerm} />
			<Container>
				<Row>
					<Col sm={12} mg={12} lg={12}>
						<Header />
					</Col>
				</Row>
				<Row>{renderStories()}</Row>
				{!isEmpty(stories) && (
					<>
						<br />
						<Row>
							<Col sm={12}>
								<Pagination
									next={query.next}
									previous={query.previous}
									onClick={paginatorClick}
									totalCount={query.count}
									perPageCount={12}
									activePage={query.currentPage}
								/>
							</Col>
						</Row>
						<br />
						<br />
					</>
				)}
			</Container>
		</>
	)
}

export default Stories
