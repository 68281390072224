import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useParams, useHistory, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { Font, Spinner, Pagination } from '../../../components'
import Media from '../../stories/Detail/helpers/Media'
import DetailSection from '../../stories/Detail/helpers/DetailSection'
import ReactionSection from '../../stories/Detail/helpers/ReactionSection'
import { dateFromNow } from '../../../utils'
import { AppState } from '../../../store/constants'
import BackButton from '../../../images/back-button.svg'
import PlaceholderAvatar from '../../../images/placeholder.png'

import UserSection from './UserSection'
import CommentList from './CommentsLazyLoad'
import {
	selectStory,
	selectAppState,
	selectQuery,
	selectReportedBy,
} from '../selector'
import {
	requestStory,
	resetStoryDetail,
	setQuery,
	setUsersReported,
	requestHideStory,
	requestMarkStorySafe,
	requestUsersReported,
} from '../actions'

import styles from './index.module.css'

const StoryDetail = () => {
	const params = useParams()
	const dispatch = useDispatch()
	const appState = useSelector(selectAppState)
	const story = useSelector(selectStory)
	const query = useSelector(selectQuery)
	const reportedBy = useSelector(selectReportedBy)
	const history = useHistory()

	useEffect(() => {
		dispatch(requestStory(params.id))
		dispatch(
			requestUsersReported({
				type: 'story',
				id: params.id,
			}),
		)
		return () => {
			dispatch(resetStoryDetail())
			dispatch(
				setQuery({
					next: null,
					previous: null,
					currentPage: 1,
					count: 0,
				}),
			)
			dispatch(setUsersReported([]))
		}
	}, [params.id, dispatch])

	const changeStoryStatus = (item) => {
		if (item.value === 'hide') {
			dispatch(requestHideStory(story.id))
			return
		}

		if (item.value === 'markSafe') {
			dispatch(requestMarkStorySafe(story.id))
		}
	}

	if (appState === AppState.LOADING || appState === AppState.IDLE) {
		return <Spinner />
	}

	const paginatorClick = (type) => {
		if (type === 'NEXT') {
			dispatch(
				setQuery({
					currentPage: query.currentPage + 1,
				}),
			)
		} else if (type === 'PREV') {
			dispatch(
				setQuery({
					currentPage: query.currentPage - 1,
				}),
			)
		} else {
			dispatch(
				setQuery({
					currentPage: type,
				}),
			)
		}
		dispatch(
			requestUsersReported({
				id: params.id,
				type: 'story',
			}),
		)
	}
	const setOnErrorImage = (e) => {
		e.currentTarget.src = PlaceholderAvatar
	}

	const renderReports = () => {
		if (isEmpty(reportedBy)) {
			return <></>
		}
		return reportedBy.map((rb) => {
			return (
				<Col
					sm={12}
					xs={12}
					lg={6}
					md={6}
					xl={6}
					key={`reported-by-${rb.id}`}
				>
					<div className={styles.reportContainer}>
						<div className={styles.reportLeft}>
							<img
								src={rb.user.picture || PlaceholderAvatar}
								onError={setOnErrorImage}
								alt=''
							/>
							<Link
								className={styles.textDecorationNone}
								to={`/users/${rb.user.id}`}
							>
								<p className={styles.reportPara}>
									{rb.user.name}
								</p>
							</Link>
						</div>
						<div className={styles.reportRight}>
							<p
								className={styles.reportPara}
								style={{ fontWeight: 'normal' }}
							>
								{rb.reportTag}
							</p>
						</div>
					</div>
				</Col>
			)
		})
	}

	return (
		<Container className='top-space'>
			<Row>
				<Col sm={4} className={styles.topSection}>
					<button
						type='button'
						onClick={() => {
							history.goBack()
						}}
						className={styles.linkContainer}
					>
						<img
							src={BackButton}
							className={styles.backSvg}
							alt='Back button'
						/>
						<Font fontAlias='P1' fontText='Back' />
					</button>
				</Col>
			</Row>
			{isEmpty(story) ? (
				<Row>
					<Font
						fontAlias='H2'
						fontText={`Story with id ${params.id} does not exist`}
					/>
				</Row>
			) : (
				<>
					<Row>
						<Media media={story.mediaUrl} type={story.mediaType} />
						<Col md={7} sm={12} className={styles.infoContainer}>
							<UserSection
								location={story.location}
								time={dateFromNow(story.date) || ''}
								user={story.user}
								isAnon={story.anon}
								active={story.active}
								markSafe={story.markSafe}
								changeStoryStatus={changeStoryStatus}
							/>
							<DetailSection
								heading={story.title}
								paragraph={story.description || ''}
							/>
							<br />
							<ReactionSection
								likeCount={story.likes}
								reshareCount={story.share}
								commentCount={story.comments}
							/>
							<br />
							<CommentList storyId={params.id} />
						</Col>
					</Row>
					<Row>
						<Col>
							<Font
								fontAlias='H2'
								fontText='Reports'
								className={styles.heading}
							/>
						</Col>
					</Row>
					<br />
					<Row>
						<Col sm={12} xs={12} lg={6} md={6} xl={6}>
							<div className={styles.reportContainer}>
								<div className={styles.reportLeft}>
									<Font fontAlias='H3' fontText='User' />
								</div>
								<div className={styles.reportRight}>
									<Font
										fontAlias='H3'
										fontText='Report Type'
									/>
								</div>
							</div>
						</Col>
					</Row>
					<Row>{renderReports()}</Row>
					<br />
					<br />
					<Row>
						<Col sm={12}>
							<Pagination
								next={query.next}
								previous={query.previous}
								onClick={paginatorClick}
								totalCount={query.count}
								perPageCount={10}
								activePage={query.currentPage}
							/>
						</Col>
					</Row>
					<br />
					<br />
				</>
			)}
		</Container>
	)
}

export default StoryDetail
