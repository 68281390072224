import React from 'react'
import { Hidden } from 'react-grid-system'

import LogoImage from '../../../../images/alertlilogo.svg'
import styles from './index.module.css'

const Logo = () => {
	return (
		<Hidden md lg xl>
			<img src={LogoImage} alt='Alertli logo' className={styles.logo} />
		</Hidden>
	)
}

export default Logo
