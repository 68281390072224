import { isEmpty } from 'lodash'

export const transformAdmins = (list) => {
	if (isEmpty(list)) {
		return []
	}

	return list.map((admin) => {
		return {
			name: admin.userprofile.full_name || 'Invalid User',
			id: admin.id,
			picture: admin.userprofile.picture || '',
			email: admin.email || 'Invalid User',
			status: admin.is_active,
		}
	})
}

export const transformQuery = (list) => {
	return {
		count: list.count,
		next: list.next,
		previous: list.previous,
		currentPage: list.currentPage,
		searchTerm: list.searchTerm,
	}
}

export const abc = 'abc'
