import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import classnames from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'
import {
	requestStoryComments,
	requestHideUnhideStoryComments,
} from '../../actions'
import { selectStoryCommentQuery, selectStoryCommentList } from '../../selector'
import { AppState } from '../../../../store/constants'
import { Spinner } from '../../../../components'

import EyeOpen from '../../../../images/eye-open.svg'
import Group from '../../../../images/group.png'
import EyeClose from '../../../../images/eye-close.svg'
import styles from './index.module.css'

const CommentList = ({ storyId }) => {
	const dispatch = useDispatch()
	const commentsQuery = useSelector(selectStoryCommentQuery)
	const comments = useSelector(selectStoryCommentList)

	useEffect(() => {
		dispatch(requestStoryComments(storyId))
	}, [storyId, dispatch])

	const commentClick = (comment) => {
		if (comment.status) {
			dispatch(
				requestHideUnhideStoryComments({
					id: comment.id,
					type: 'hide',
				}),
			)
		} else {
			dispatch(
				requestHideUnhideStoryComments({
					id: comment.id,
					type: 'unhide',
				}),
			)
		}
	}

	const renderComments = () => {
		return comments.map((comment) => {
			return (
				<div
					className={styles.commentContainer}
					key={`comment-${comment.id}`}
				>
					<img
						src={comment.user.picture}
						alt=''
						className={styles.commentAvatar}
					/>
					<div className={styles.commentContent}>
						<div className={styles.commentContentTop}>
							<Link
								className={styles.textDecorationNone}
								to={`/users/${comment.user.id}`}
							>
								<h2>{comment.user.name}</h2>
							</Link>
							<span>4h</span>
						</div>
						<p>{comment.description}</p>
						<span>
							<b>{comment.likes}</b> Likes
						</span>
						<div className={styles.commentBorder}>&nbsp;</div>
					</div>
					<button
						className={styles.commentOption}
						type='button'
						onClick={() => commentClick(comment)}
					>
						{comment.status ? (
							<img
								className={styles.commentOptionSvg}
								src={EyeOpen}
								alt='Eye Open Icon'
							/>
						) : (
							<img
								className={styles.commentOptionSvg}
								src={EyeClose}
								alt='Eye Close Icon'
							/>
						)}
					</button>
				</div>
			)
		})
	}

	const fetchMore = () => {
		dispatch(requestStoryComments(storyId))
	}

	if (
		commentsQuery.commentLoading === AppState.LOADING ||
		commentsQuery.commentLoading === AppState.IDLE
	) {
		return <Spinner />
	}

	if (isEmpty(comments)) {
		return (
			<div className={styles.commentListContainer}>
				<div className={styles.commentEmptyContainer}>
					<img src={Group} alt='Comment' className={styles.logo} />
					<h2 style={{ color: '#9ea2a6', marginTop: '15px' }}>
						No Comments
					</h2>
				</div>
			</div>
		)
	}

	const scrollBarStyle = classnames(
		styles.commentListContainer,
		styles.scrollBarStyle,
	)

	return (
		<div className={scrollBarStyle} id='scrollable'>
			<InfiniteScroll
				scrollThreshold={0.7}
				dataLength={commentsQuery.commentCount}
				scrollableTarget='scrollable'
				next={fetchMore}
				loader={() => <h2>loading</h2>}
				hasMore={commentsQuery.canGoNext}
			>
				{renderComments()}
			</InfiniteScroll>
		</div>
	)
}

CommentList.propTypes = {
	storyId: PropTypes.string.isRequired,
}

export default CommentList
