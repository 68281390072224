import { createSelector } from 'reselect'

import { transformAdmins, transformQuery } from './transform'

export const selectAppState = createSelector(
	(state) => state.admins,
	(admins) => admins.appState,
)

export const selectAdmins = createSelector(
	(state) => state.admins,
	(admins) => transformAdmins(admins.list.data),
)

export const selectQuery = createSelector(
	(state) => state.admins,
	(admins) => transformQuery(admins.list),
)

export const selectError = createSelector(
	(state) => state.admins,
	(admins) => admins.hasError,
)
