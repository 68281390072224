/* eslint-disable react/button-has-type */
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import LoadSvg from '../LoadSvg'
import styles from './index.module.css'
import Loader from './loader-light.svg'

const Button = ({
	buttonText,
	className,
	buttonAlias,
	loading,
	disabled,
	type,
	onClick,
}) => {
	if (buttonAlias === 'Primary') {
		const primaryBtn = classnames(styles.primaryBtn, className)
		return (
			<>
				<button
					type={type}
					className={primaryBtn}
					disabled={disabled}
					onClick={onClick}
				>
					{loading ? (
						<LoadSvg className={styles.spinner} svg={Loader} />
					) : (
						buttonText
					)}
				</button>
			</>
		)
	}

	if (buttonAlias === 'PrimaryOutline') {
		const primaryOutline = classnames(styles.primaryOutline, className)

		return (
			<button
				type={type}
				onClick={onClick}
				className={primaryOutline}
				disabled={disabled}
			>
				{loading ? (
					<LoadSvg className={styles.spinner} svg={Loader} />
				) : (
					buttonText
				)}
			</button>
		)
	}

	return <></>
}

Button.propTypes = {
	buttonText: PropTypes.string.isRequired,
	buttonAlias: PropTypes.oneOf(['Primary', 'PrimaryOutline']),
	className: PropTypes.string,

	type: PropTypes.string,
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
}

Button.defaultProps = {
	buttonAlias: 'Primary',
	loading: false,
	disabled: false,
	onClick: () => {},
	className: '',
	type: 'submit',
}

export default Button
