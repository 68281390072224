import React from 'react'
import { Link } from 'react-router-dom'

import styles from './index.module.css'
import Font from '../Font'

export default function index() {
	return (
		<div className={`${styles.container} top-space`}>
			<Font fontAlias='H2' fontText='404 Page Not Found' />
			<Link to='/' className={styles.button}>
				Go Back
			</Link>
		</div>
	)
}
