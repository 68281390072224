import React, { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory, Link } from 'react-router-dom'
import { Container, Row, Col, Hidden, Visible } from 'react-grid-system'
import PlaceholderAvatar from '../../../images/placeholder.png'

import { AppState } from '../../../store/constants'
import BackButton from '../../../images/back-button.svg'
import { Font, Spinner, Pagination, Select } from '../../../components'

import {
	requestUser,
	setQuery,
	requestUsersReported,
	setUsersReported,
	requestHideUser,
} from '../actions'
import {
	selectAppState,
	selectUser,
	selectQuery,
	selectReportedBy,
} from '../selector'

import styles from './index.module.css'

const User = () => {
	const dispatch = useDispatch()
	const params = useParams()
	const appState = useSelector(selectAppState)
	const user = useSelector(selectUser)
	const query = useSelector(selectQuery)
	const reportedBy = useSelector(selectReportedBy)
	const history = useHistory()

	useEffect(() => {
		dispatch(requestUser(params.id))
		dispatch(
			requestUsersReported({
				id: params.id,
				type: 'user',
			}),
		)
		return () => {
			dispatch(
				setQuery({
					next: null,
					previous: null,
					currentPage: 1,
					count: 0,
				}),
			)
			dispatch(setUsersReported([]))
		}
	}, [dispatch, params.id])

	if (appState === AppState.IDLE || appState === AppState.LOADING) {
		return <Spinner />
	}

	const paginatorClick = (type) => {
		if (type === 'NEXT') {
			dispatch(
				setQuery({
					currentPage: query.currentPage + 1,
				}),
			)
		} else if (type === 'PREV') {
			dispatch(
				setQuery({
					currentPage: query.currentPage - 1,
				}),
			)
		} else {
			dispatch(
				setQuery({
					currentPage: type,
				}),
			)
		}
		dispatch(
			requestUsersReported({
				id: params.id,
				type: 'user',
			}),
		)
	}

	// const hideUser = () => {
	// 	dispatch(
	// 		requestHideUser({
	// 			id: params.id,
	// 			isActive: false,
	// 		}),
	// 	)
	// }

	const renderReports = () => {
		if (isEmpty(reportedBy)) {
			return <></>
		}
		return reportedBy.map((rb) => {
			return (
				<Col
					sm={12}
					xs={12}
					lg={6}
					md={6}
					xl={6}
					key={`reported-by-${rb.id}`}
				>
					<div className={styles.reportContainer}>
						<div className={styles.reportLeft}>
							<img
								src={rb.user.picture || PlaceholderAvatar}
								alt='Profile'
							/>
							<Link
								className={styles.reportPara}
								to={`/users/${rb.user.id}`}
							>
								{rb.user.name}
							</Link>
						</div>
						<div className={styles.reportRight}>
							<p
								className={styles.reportPara}
								style={{ fontWeight: 'normal' }}
							>
								{rb.reportTag}
							</p>
						</div>
					</div>
				</Col>
			)
		})
	}

	const clickedItem = (item) => {
		const isActive = item.value === 'enabled'
		dispatch(
			requestHideUser({
				id: params.id,
				isActive,
			}),
		)
	}

	const setOnErrorImage = (e) => {
		e.currentTarget.src = PlaceholderAvatar
	}

	const renderUserDetail = () => {
		return (
			<>
				<Row className={styles.marginZero}>
					<img
						src={user.picture || PlaceholderAvatar}
						alt='User avatar'
						className={styles.profilePicture}
						onError={setOnErrorImage}
					/>
					<Col
						xs={12}
						sm={12}
						md={6}
						lg={8}
						xl={8}
						className={styles.profileDetail}
					>
						<div className={styles.marginTop20}>
							<span className={styles.heading}>{user.name}</span>
							<Hidden md sm xs>
								<div className={styles.optionsSec}>
									<Select
										items={[
											{
												name: user.isActive
													? 'Block'
													: 'Enabled',
												value: user.isActive
													? 'block'
													: 'enabled',
												canOpenModal: true,
												modalText: user.isActive
													? 'Are you sure you want to block this user'
													: 'Are you sure you  want to enable this user',
											},
										]}
										itemClicked={clickedItem}
									/>
								</div>
							</Hidden>
						</div>
						<p className={styles.para}>{user.email}</p>
						<p className={styles.para}>{user.mobile}</p>
						<p className={`${styles.para} ${styles.paraStrong}`}>
							<strong>{user.totalPosts}</strong> Posts
						</p>
					</Col>
				</Row>
			</>
		)
	}

	return (
		<Container>
			<Row className={styles.topSection}>
				<Col xs={6} sm={6} md={6} lg={6}>
					<button
						onClick={() => {
							history.goBack()
						}}
						type='button'
						className={styles.linkContainer}
					>
						<img
							src={BackButton}
							className={styles.backSvg}
							alt='Back button'
						/>
						<Font fontAlias='P1' fontText='Back' />
					</button>
				</Col>
				{!isEmpty(user) && (
					<Col xs={6} sm={6} md={6} lg={6}>
						{/* {user.isActive && (
							<Button
								buttonText='Hide User'
								type='button'
								onClick={hideUser}
								className={styles.button}
							/>
						)} */}
						<Visible md sm xs>
							<div className={styles.options}>
								<Select
									items={[
										{
											name: user.isActive
												? 'Block'
												: 'Enabled',
											value: user.isActive
												? 'block'
												: 'enabled',
											canOpenModal: true,
											modalText: user.isActive
												? 'Are you sure you want to block this user'
												: 'Are you sure you  want to enable this user',
										},
									]}
									itemClicked={clickedItem}
								/>
							</div>
						</Visible>
					</Col>
				)}
			</Row>
			<br />
			{isEmpty(user) ? (
				<Row>
					<Col sm={12} md={12} lg={12} xs={12} xl={12}>
						<Font
							fontAlias='H3'
							fontText={`User with id ${params.id} does not exist`}
						/>
					</Col>
				</Row>
			) : (
				<>
					{renderUserDetail()}
					<br />
					<Row>
						<Col>
							<Font
								fontAlias='H2'
								fontText='Reports'
								className={styles.heading}
							/>
						</Col>
					</Row>
					<br />
					<Row>
						<Col sm={12} xs={12} lg={6} md={6} xl={6}>
							<div className={styles.reportContainer}>
								<div className={styles.reportLeft}>
									<Font fontAlias='H3' fontText='User' />
								</div>
								<div className={styles.reportRight}>
									<Font
										fontAlias='H3'
										fontText='Report Type'
									/>
								</div>
							</div>
						</Col>
					</Row>
					<Row>{renderReports()}</Row>
					<br />
					<br />
					<Row>
						<Col sm={12}>
							<Pagination
								next={query.next}
								previous={query.previous}
								onClick={paginatorClick}
								totalCount={query.count}
								perPageCount={10}
								activePage={query.currentPage}
							/>
						</Col>
					</Row>
					<br />
					<br />
				</>
			)}
		</Container>
	)
}

export default User
