import { all, call } from 'redux-saga/effects'
import mainSaga from './main/saga'
import authSaga from '../domains/auth/saga'
import storiesSaga from '../domains/stories/saga'
import adminsSaga from '../domains/admins/saga'
import reportsSaga from '../domains/reports/saga'
import usersSaga from '../domains/users/saga'
import contributionsSaga from '../domains/contributions/saga'

export default function* root() {
	yield all([
		call(mainSaga),
		call(authSaga),
		call(storiesSaga),
		call(adminsSaga),
		call(reportsSaga),
		call(usersSaga),
		call(contributionsSaga),
	])
}
