import { AppState } from '../../store/constants'
import { AdminsActions } from './constants'

const initialState = {
	appState: AppState.IDLE,

	hasError: false,

	list: {
		data: [],
		count: 0,
		next: null,
		previous: null,
		currentPage: 1,
		searchTerm: '',
	},
}

export default (state = initialState, action) => {
	const { type, payload } = action

	switch (type) {
		case AdminsActions.setAppState: {
			return {
				...state,
				appState: payload,
			}
		}
		case AdminsActions.setAdmins: {
			return {
				...state,
				list: {
					...state.list,
					data: payload.list,
					count: payload.count,
					next: payload.next,
					previous: payload.previous,
				},
			}
		}
		case AdminsActions.setCurrentPage: {
			return {
				...state,
				list: {
					...state.list,
					searchTerm: payload.searchTerm,
					currentPage: payload.currentPage,
				},
			}
		}
		case AdminsActions.setChangeAdmin: {
			const data = state.list.data.map((admin) => {
				if (admin.id === payload.id) {
					return payload
				}
				return admin
			})

			return {
				...state,
				list: {
					...state.list,
					data,
				},
			}
		}
		case AdminsActions.setError: {
			return {
				...state,
				hasError: true,
			}
		}
		case AdminsActions.clearError: {
			return {
				...state,
				hasError: false,
			}
		}
		default: {
			return state
		}
	}
}
