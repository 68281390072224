import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import UnauthenticatedApp from './unauthenticated-app'
import { FullPageSpinner } from './components'
import AuthenticatedApp from './authenticated-app'

import { selectAuthStatus, selectAppState } from './store/main/selector'
import { getToken } from './utils'
import { requestProfile, setAppState } from './store/main/actions'
import { AppState } from './store/constants'

function App() {
	const appState = useSelector(selectAppState)
	const isAuthenticated = useSelector(selectAuthStatus)
	const dispatch = useDispatch()

	useEffect(() => {
		const token = getToken()

		if (token) {
			dispatch(requestProfile())
		} else {
			dispatch(setAppState(AppState.READY))
		}
	}, [dispatch])

	if (appState === AppState.IDLE) {
		return <></>
	}

	if (appState === AppState.LOADING) {
		return <FullPageSpinner />
	}

	if (isAuthenticated) {
		return <AuthenticatedApp />
	}

	return <UnauthenticatedApp />
}

export default App
