import { MainActions } from './constants'
import { removeToken } from '../../utils'

export const setUser = (payload) => ({
	type: MainActions.setUser,
	payload,
})

export const setAppState = (payload) => ({
	type: MainActions.setAppState,
	payload,
})

export const requestProfile = () => ({
	type: MainActions.requestUserProfile,
})

export const setLogout = () => {
	removeToken()
	return {
		type: MainActions.setLogout,
	}
}

export const requestChangePassword = (payload) => {
	return {
		type: MainActions.requestChangePassword,
		payload,
	}
}
